<div class="wrapper mat-typography" *appLoader="displayedDevices$">
  <div class="filter" *ngIf="(screensizeIndex$ | async) >= 2">
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
  </div>

  <mat-expansion-panel class="filter" *ngIf="(screensizeIndex$ | async) < 2">
    <mat-expansion-panel-header>
      <mat-panel-title> Filter </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template *ngTemplateOutlet="formTpl"></ng-template>
  </mat-expansion-panel>
  <section class="device-stats stats mat-elevation-z4">
    <div class="table">
      <p class="overview-title">Devices</p>
      <div class="overview" *ngIf="monitoringStats$ | async as stats">
        <div class="overview-row">
          <mat-icon>fiber_manual_record</mat-icon>
          <p class="overview-text">No Warning</p>
          <p class="overview-text green">
            {{ stats.devices_with_no_warning }}
          </p>
        </div>
        <div class="overview-row">
          <mat-icon>fiber_manual_record</mat-icon>
          <p class="overview-text">Low Battery</p>
          <p class="overview-text yellow">
            {{ stats.devices_with_low_battery }}
          </p>
        </div>
        <div class="overview-row">
          <mat-icon>fiber_manual_record</mat-icon>
          <p class="overview-text">Connection Error</p>
          <p class="overview-text orange">
            {{ stats.devices_with_last_uplink_error }}
          </p>
        </div>
        <div class="overview-row">
          <mat-icon>fiber_manual_record</mat-icon>
          <p class="overview-text">Battery and Connection</p>
          <p class="overview-text red">
            {{ stats.devices_with_low_battery_last_uplink_error }}
          </p>
        </div>
        <div class="overview-row">
          <mat-icon>fiber_manual_record</mat-icon>
          <p class="overview-text">Unknown</p>
          <p class="overview-text grey">
            {{ stats.devices_with_no_message_yet }}
          </p>
        </div>
      </div>
    </div>
    <div class="vertical-line" *ngIf="(screensizeIndex$ | async) !== 2"></div>
    <div>
      <app-device-status-chart
        *ngIf="(screensizeIndex$ | async) !== 2"
        [statistics]="monitoringStats$ | async"
      ></app-device-status-chart>
    </div>
  </section>
  <section class="gateway-stats stats mat-elevation-z4">
    <div class="table">
      <p class="overview-title">Gateways</p>
      <div class="overview" *ngIf="gatewayStats$ | async as gwstats">
        <div class="overview-row">
          <mat-icon>fiber_manual_record</mat-icon>
          <p class="overview-text">Active</p>
          <p class="overview-text green">{{ gwstats.active }}</p>
        </div>
        <div class="overview-row">
          <mat-icon>fiber_manual_record</mat-icon>
          <p class="overview-text">Inactive</p>
          <p class="overview-text red">
            {{ gwstats.inactive }}
          </p>
        </div>
      </div>
    </div>
    <div class="vertical-line" *ngIf="(screensizeIndex$ | async) !== 2"></div>
    <div>
      <app-gateway-status-chart
        *ngIf="(screensizeIndex$ | async) !== 2"
        [statistics]="gatewayStats$ | async"
      ></app-gateway-status-chart>
    </div>
  </section>
  <ng-container>
    <section class="list">
      <app-device-list [devices]="displayedDevices$ | async"></app-device-list>
    </section>
  </ng-container>

  <ng-template #formTpl>
    <form [formGroup]="filterForm" *ngIf="filterForm">
      <mat-form-field class="filter-select">
        <mat-label>Site</mat-label>
        <mat-select formControlName="project_id">
          <mat-option [value]="0">All</mat-option>
          <mat-option *ngFor="let site of sites$ | async" [value]="site.tag_id">
            {{ site?.metadata?.iso_code }} -
            {{ site?.metadata?.site_long_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filter-select">
        <mat-label>Brand</mat-label>
        <mat-select formControlName="brand_id">
          <mat-option [value]="0">All</mat-option>
          <mat-option
            *ngFor="let brand of brands$ | async"
            [value]="brand.brand_id"
          >
            {{ brand.brand_id }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filter-select">
        <mat-label>Model</mat-label>
        <mat-select formControlName="device_type_id">
          <mat-option [value]="0">All</mat-option>
          <mat-option
            *ngFor="let model of models$ | async"
            [value]="model?.device_type_id"
            >{{ model?.metadata?.label }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filter-select">
        <mat-label>Source Platform</mat-label>
        <mat-select formControlName="source">
          <mat-option [value]="0">All</mat-option>
          <mat-option
            *ngFor="let source of sources$ | async"
            [value]="source.source_id"
            >{{ source.metadata.label }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filter-select">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option [value]="''">All</mat-option>
          <mat-option [value]="'1'">No Warning</mat-option>
          <mat-option [value]="'2'">Low Battery</mat-option>
          <mat-option [value]="'3'">Connection Error</mat-option>
          <mat-option [value]="'4'">Battery and Connection</mat-option>
          <mat-option [value]="'5'">Unknown</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="spacer" *ngIf="(screensizeIndex$ | async) >= 2"></div>
      <button mat-button (click)="openMap(mapTmpl)" class="map-btn">
        <mat-icon>map</mat-icon> Map
      </button>
    </form>
  </ng-template>

  <ng-template #mapTmpl>
    <ng-container *ngIf="displayedDevices$ | async as displayedDevices">
      <div class="map-container">
        <app-display-position-map
          [devices]="displayedDevices"
        ></app-display-position-map>
      </div>
      <button mat-button [mat-dialog-close]="false" class="dialog-btn">
        Close
      </button>
    </ng-container>
  </ng-template>
</div>
