<h2 *ngIf="!deviceType" mat-dialog-title>Create a new device type</h2>
<h2 *ngIf="deviceType" mat-dialog-title>
  Edit device type {{ deviceType.device_type_id }}
</h2>
<div mat-dialog-content>
  <div class="form-wrapper" [formGroup]="modelForm">
    <div class="centered-content">
      <app-image-upload
        class="image"
        [img]="imageFile.value"
        [imgPreviewUrl]="imagePreviewUrl"
        (imgSelected)="imageFile.setValue($event)"
      ></app-image-upload>
    </div>
    <div class="col-2">
      <mat-form-field appearance="outline">
        <mat-label>Title</mat-label>
        <input matInput formControlName="device_model_name" />
        <mat-error
          *ngIf="modelForm.get('device_model_name')?.hasError('pattern')"
        >
          can only contain letters and _
        </mat-error>
        <mat-error
          *ngIf="modelForm.get('device_model_name')?.hasError('required')"
          >This field is required!</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Connectivity</mat-label>
        <mat-select formControlName="connectivity">
          <mat-option value="LoRaWAN">LoRaWAN</mat-option>
          <mat-option value="MQTT">MQTT</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Brand</mat-label>
        <mat-select formControlName="brand">
          <mat-option
            *ngFor="let brand of brands | async"
            [value]="brand.brand_id"
            >{{ brand.brand_id }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Battery powered</mat-label>
        <mat-select formControlName="battery_powered">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- <div class="range-type-form">
      <div class="range-type-header">
        <h3>Measurement Ranges</h3>
        <button
          mat-mini-fab
          color="primary"
          (click)="addRangeType()"
          matTooltipPosition="below"
          matTooltip="add a measurement range type"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="range-type-list">
        <div
          *ngFor="let rangeTypeForm of rangeTypeForms.controls; let i = index"
          class="range-type-item"
          [formGroup]="rangeTypeForm"
        >
          <div class="col-3">
            <mat-form-field appearance="outline">
              <mat-label>Measurement Type</mat-label>
              <mat-select formControlName="type">
                <mat-option
                  *ngFor="let type of measurementTypeNames"
                  [value]="type"
                  >{{ type }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Unit</mat-label>
              <mat-select formControlName="unit">
                <mat-option
                  *ngFor="
                    let unit of getMeasurementTypeUnits(
                      rangeTypeForm.value.type
                    )
                  "
                  [value]="unit"
                >
                  {{ unit.sign }} - {{ unit.detail }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="centered-content">
              <button
                mat-mini-fab
                color="warn"
                (click)="removeRangeType(i)"
                matTooltip="remove this measurement range type"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
          <ng-container
            *ngFor="let range of ranges(rangeTypeForm).controls; let i = index"
          >
            <div class="col-3" [formGroup]="range">
              <mat-form-field appearance="outline">
                <mat-label>Min</mat-label>
                <input matInput formControlName="min" type="number" />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Max</mat-label>
                <input matInput formControlName="max" type="number" />
              </mat-form-field>
              <div class="centered-content">
                <button
                  mat-mini-fab
                  color="warn"
                  (click)="removeRange(rangeTypeForm, i)"
                  [style.visibility]="
                    ranges(rangeTypeForm).controls.length <= 1
                      ? 'hidden'
                      : 'visible'
                  "
                  matTooltip="remove this range"
                >
                  <mat-icon>remove</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
          <div class="centered-content">
            <button
              mat-raised-button
              color="primary"
              (click)="addRange(rangeTypeForm)"
            >
              Add range
            </button>
          </div>

          <div class="row-seperator"></div>
        </div>
      </div>
    </div>

    <div class="firmware-form">
      <div class="firmware-header">
        <h3>Firmware</h3>
        <button
          mat-mini-fab
          color="primary"
          (click)="addFirmware()"
          matTooltipPosition="below"
          matTooltip="add a firmware version"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="firmware-list">
        <div
          *ngFor="let firmware of firmwareForms.controls; let i = index"
          class="firmware-item"
        >
          <mat-form-field appearance="outline">
            <mat-label>Version *</mat-label>
            <input matInput [formControl]="firmware" />
          </mat-form-field>
          <button
            mat-mini-fab
            color="warn"
            (click)="removeFirmware(i)"
            matTooltipPosition="below"
            matTooltip="remove this firmware version"
            [style.visibility]="
              firmwareForms.controls.length <= 1 ? 'hidden' : 'visible'
            "
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div> -->
    <div class="col-2" *ngIf="deviceType">
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          matInput
          formControlName="description"
          rows="8"
          style="resize: none"
        ></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Installation</mat-label>
        <textarea
          matInput
          formControlName="installation"
          rows="8"
          style="resize: none"
        ></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Dimensions</mat-label>
        <input matInput formControlName="dimensions" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Weight</mat-label>
        <input matInput formControlName="weight" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Battery</mat-label>
        <input matInput formControlName="battery" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Battery life</mat-label>
        <input matInput formControlName="battery_life" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Configurable</mat-label>
        <input matInput formControlName="configurable" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Frequency</mat-label>
        <input matInput formControlName="frequency" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Security</mat-label>
        <input matInput formControlName="security" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Atex</mat-label>
        <mat-select formControlName="atex">
          <mat-option [value]="true">Yes</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button
    mat-button
    [mat-dialog-close]="formValues"
    cdkFocusInitial
    [disabled]="modelForm.invalid"
  >
    Confirm
  </button>
</div>
