import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  GeneralRouteInfo,
  Interface,
  PubSubMessage,
  RouteDto,
} from 'src/models/data-routing.models';
import { HierarchyNode } from 'src/models/device-hierarchy.models';
import {
  Brand,
  DeviceTypeDto,
  DeviceTypeInfo,
  HexDecoderBluePrint,
  Source,
} from 'src/models/device-type.models';
import {
  BrandInfo,
  DefaultDeviceInfo,
  DeviceAlarm,
  DeviceDto,
  DeviceMonitoringStats,
  DevicePictureInfo,
  DeviceUpdateInfo,
  Downlink,
  FireflyDeviceInfo,
  IotCoreDeviceInfo,
  SiteNotificationConfig,
  SiteTag,
} from 'src/models/device.models';
import { Gateway, GatewayImportInfo } from 'src/models/gateway.models';
import { SiteInfo } from 'src/models/site.models';
import { User, UserAlertInfo } from 'src/models/user.models';
import { Country } from './../../../models/countries.models';
import { DecoderBluePrint } from './../../../models/device-type.models';
import { RssiHistoryData } from './../../../models/gateway.models';
import { Organization } from './../../../models/organization.models';
import { UserInfo } from './../../../models/user.models';
import { CustomHttpParamEncoder } from './custom-http-param-encoder';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  public configsUrl: string = environment.apiUrlConfigs;
  public commandsUrl: string = environment.apiUrlCommands;

  constructor(private http: HttpClient) {}

  //---------------------------------------------------------------------------
  // Brands
  //---------------------------------------------------------------------------

  public getBrands(): Observable<Brand[]> {
    return this.http.get<Brand[]>(this.configsUrl + 'brands');
  }

  public createBrand(brand: BrandInfo): Observable<Brand> {
    const formData = new FormData();
    Object.keys(brand).forEach((key) => formData.append(key, brand[key]));

    return this.http.post<Brand>(`${this.configsUrl}brands`, formData);
  }

  public updateBrand(brand: BrandInfo): Observable<Brand> {
    const encodedId = encodeURIComponent(brand.name);
    const formData = new FormData();
    Object.keys(brand).forEach((key) => formData.append(key, brand[key]));

    return this.http.put<Brand>(
      `${this.configsUrl}brands/${encodedId}`,
      formData,
    );
  }

  public deleteBrand(brandId: string): Observable<string> {
    return this.http.delete<string>(`${this.configsUrl}brands/${brandId}`);
  }

  //---------------------------------------------------------------------------
  // Codecs
  //---------------------------------------------------------------------------

  public getCodecList(): Observable<string[]> {
    return this.http.get<string[]>(`${this.configsUrl}codecs`);
  }

  public decodeTestPayload(
    payload: string,
    blueprint: HexDecoderBluePrint,
    port?: number,
  ) {
    return this.http.post<{ statusCode: number }>(
      `${this.configsUrl}codecs/test`,
      {
        payload,
        blueprint,
        port,
      },
    );
  }

  //---------------------------------------------------------------------------
  // Countries
  //---------------------------------------------------------------------------

  public getCountries(): Observable<Country[]> {
    return this.http
      .get<Country[]>(this.configsUrl + 'countries')
      .pipe(
        map((response) =>
          response.sort((a, b) => a.name.localeCompare(b.name)),
        ),
      );
  }

  //---------------------------------------------------------------------------
  // Devices
  //---------------------------------------------------------------------------

  public getDevices(
    filter: { [key: string]: string | null } = {},
  ): Observable<DeviceDto[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    Object.keys(filter)
      .filter((key) => filter[key])
      .forEach((key) => (params = params.append(key, filter[key] as string)));

    return this.http.get<DeviceDto[]>(this.configsUrl + 'devices', {
      params,
    });
  }

  public getDeviceById(id: string): Observable<DeviceDto> {
    return this.http.get<DeviceDto>(this.configsUrl + 'devices/' + id);
  }

  public getDeviceHierarchyTree() {
    return this.http.get<HierarchyNode[]>(
      this.configsUrl + 'devices/hierarchy_tree',
    );
  }

  public createDevice(
    deviceInfo: FireflyDeviceInfo | DefaultDeviceInfo | IotCoreDeviceInfo,
    source: string,
  ): Observable<DeviceDto> {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    }).append('source', source.toLowerCase());

    return this.http.post<DeviceDto>(this.configsUrl + 'devices', deviceInfo, {
      params,
    });
  }

  public createDevices(payload: unknown, source: string) {
    const params = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    }).append('source', source.toLowerCase());

    return this.http.post<{ created: DeviceDto[] }>(
      this.configsUrl + 'devices/csv',
      payload,
      {
        params,
      },
    );
  }

  public updateDevice(deviceInfo: DeviceUpdateInfo): Observable<DeviceDto> {
    return this.http.put<DeviceDto>(
      `${this.configsUrl}devices/${deviceInfo.device_id}`,
      deviceInfo,
    );
  }

  public deleteDevice(device: DeviceDto): Observable<void> {
    return this.http.delete<void>(
      this.configsUrl + `devices/${device.device_id}`,
    );
  }

  public updateDevicePicture(
    deviceId: string,
    devicePicture: DevicePictureInfo,
  ): Observable<DeviceDto> {
    const formData = new FormData();
    formData.append('image_file', devicePicture.image_file);
    return this.http.put<DeviceDto>(
      `${this.configsUrl}devices/${deviceId}/picture`,
      formData,
    );
  }

  public updateDeviceAlarm(id: string, alarm: DeviceAlarm): Observable<void> {
    return this.http.put<void>(`${this.configsUrl}devices/${id}/alarm`, alarm);
  }

  public setDeviceCalibration(
    deviceId: string,
    calibrations: unknown,
  ): Observable<DeviceDto> {
    return this.http.put<DeviceDto>(
      this.configsUrl + `devices/${deviceId}/calibration`,
      calibrations,
    );
  }

  public moveDevices(
    deviceIds: string[],
    hierarchyPrefix: string,
    projectId: string,
  ): Observable<DeviceDto[]> {
    return this.http.put<DeviceDto[]>(this.configsUrl + 'devices/hierarchy', {
      data: deviceIds.map((deviceId) => ({
        deviceId,
        hierarchyPrefix,
        projectId,
      })),
    });
  }

  //---------------------------------------------------------------------------
  // DeviceDownlinks
  //---------------------------------------------------------------------------

  public sendDownlink(
    deviceId: string,
    downlinkInfo: unknown,
  ): Observable<string> {
    return this.http.post<string>(
      `${this.commandsUrl}internal/devices/${deviceId}`,
      downlinkInfo,
    );
  }

  public sendMultiDownlink(
    deviceIds: string[],
    downlinkInfo: unknown,
  ): Observable<string> {
    return this.http.post<string>(`${this.commandsUrl}internal/devices`, {
      device_ids: deviceIds,
      ...(downlinkInfo as object),
    });
  }

  public getDeviceDownlinks(id: string): Observable<Downlink[]> {
    return this.http.get<Downlink[]>(
      `${this.configsUrl}devices/${id}/downlinks`,
    );
  }

  //---------------------------------------------------------------------------
  // DeviceMessages
  //---------------------------------------------------------------------------

  public getDeviceMessages(
    device: DeviceDto,
    minTimestamp = 0,
    maxTimestamp = Date.now(),
    messageLimit = 5,
    pageAction?: 'PREV' | 'NEXT',
    referenceTimestamp = 0,
  ): Observable<PubSubMessage[]> {
    const url = `${this.configsUrl}telemetries/${device.tags[0].tag_id}/messages`;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params
      .append('min_timestamp', minTimestamp.toString())
      .append('max_timestamp', maxTimestamp.toString())
      .append('limit', messageLimit.toString())
      .append('device_id', device.device_id);

    if (pageAction) {
      params = params
        .append('pageAction', pageAction)
        .append('referenceTimestamp', referenceTimestamp.toString());
    }

    return this.http.get<PubSubMessage[]>(url, { params });
  }

  public getDeviceMessagesCount(
    device: DeviceDto,
    minTimestamp = 0,
    maxTimestamp = Date.now(),
  ): Observable<number> {
    const url = `${this.configsUrl}telemetries/${device.tags[0].tag_id}/messages/count`;
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params
      .append('min_timestamp', minTimestamp.toString())
      .append('max_timestamp', maxTimestamp.toString())
      .append('device_id', device.device_id);

    return this.http.get<number>(url, { params });
  }

  public getLastMessageOfDevice(device: DeviceDto): Observable<PubSubMessage> {
    return this.http.get<PubSubMessage>(
      `${this.configsUrl}telemetries/${device.tags[0].tag_id}/messages/${device.device_id}/last`,
    );
  }

  //---------------------------------------------------------------------------
  // DeviceMonitoringStats
  //---------------------------------------------------------------------------

  public getMonitoringStats(
    filter: { [key: string]: string | null } = {},
  ): Observable<DeviceMonitoringStats> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    Object.keys(filter)
      .filter((key) => filter[key])
      .forEach((key) => (params = params.append(key, filter[key] as string)));

    return this.http.get<DeviceMonitoringStats>(
      this.configsUrl + 'monitoring/statistics',
      { params },
    );
  }

  //---------------------------------------------------------------------------
  // DeviceTypes
  //---------------------------------------------------------------------------

  public getDeviceTypes(): Observable<DeviceTypeDto[]> {
    return this.http.get<DeviceTypeDto[]>(this.configsUrl + 'device_types');
  }

  public getDeviceTypeByID(
    id: string,
    full = false,
  ): Observable<DeviceTypeDto> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params.append('full', full.toString());
    return this.http.get<DeviceTypeDto>(
      `${this.configsUrl}device_types/${encodeURIComponent(id)}`,
      { params },
    );
  }

  public createDeviceType(
    type: DeviceTypeInfo,
    imageFile: File | null,
  ): Observable<DeviceTypeDto> {
    const formData = new FormData();
    formData.append('device_type_info', JSON.stringify(type));
    if (imageFile) {
      formData.append('image_file', imageFile);
    }
    return this.http.post<DeviceTypeDto>(
      `${this.configsUrl}device_types`,
      formData,
    );
  }

  public updateDeviceType(
    typeId: string,
    type: DeviceTypeInfo,
    imageFile?: File,
  ): Observable<DeviceTypeDto> {
    const encodedId = encodeURIComponent(typeId);
    const formData = new FormData();
    formData.append('device_type_info', JSON.stringify(type));
    if (imageFile) {
      formData.append('image_file', imageFile);
    }
    return this.http.put<DeviceTypeDto>(
      `${this.configsUrl}device_types/${encodedId}`,
      formData,
    );
  }

  public updateDeviceTypeCodec(
    typeId: string,
    decodingBluePrint: DecoderBluePrint,
  ): Observable<DeviceTypeDto> {
    const encodedId = encodeURIComponent(typeId);
    return this.http.put<DeviceTypeDto>(
      `${this.configsUrl}device_types/parser/${encodedId}`,
      decodingBluePrint,
    );
  }

  public changeDeviceTypeActiveState(
    deviceTypeId: string,
    state: boolean,
  ): Observable<boolean> {
    const encodedId = encodeURIComponent(deviceTypeId);
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params.append('disabled', state.toString());

    return this.http.patch<boolean>(
      `${this.configsUrl}device_types/${encodedId}`,
      undefined,
      {
        params,
      },
    );
  }

  public deleteDeviceType(deviceTypeId: string): Observable<number> {
    const encodedId = encodeURIComponent(deviceTypeId);
    return this.http.delete<number>(
      `${this.configsUrl}device_types/${encodedId}`,
    );
  }

  //---------------------------------------------------------------------------
  // Gateways
  //---------------------------------------------------------------------------

  public getGateways(): Observable<Gateway[]> {
    return this.http.get<Gateway[]>(`${this.configsUrl}gateways`);
  }

  public importGateway(
    gatewayImportInfo: GatewayImportInfo,
  ): Observable<Gateway> {
    return this.http.post<Gateway>(
      this.configsUrl + `gateways/import`,
      gatewayImportInfo,
    );
  }

  //---------------------------------------------------------------------------
  // GatewayRssi
  //---------------------------------------------------------------------------

  public getGatewayRssi(gatewayId: string): Observable<RssiHistoryData[]> {
    return this.http.get<RssiHistoryData[]>(
      `${this.configsUrl}gateways/${gatewayId}/rssi`,
    );
  }

  //---------------------------------------------------------------------------
  // Interfaces
  //---------------------------------------------------------------------------

  public getRoutingInterfaces(): Observable<Interface[]> {
    return this.http.get<Interface[]>(this.configsUrl + 'interfaces');
  }

  public createInterface(interf: Interface): Observable<Interface> {
    return this.http.post<Interface>(`${this.configsUrl}interfaces`, interf);
  }

  public updateInterface(interf: Interface): Observable<Interface> {
    return this.http.put<Interface>(
      `${this.configsUrl}interfaces/${interf.interface_id}`,
      interf,
    );
  }

  //---------------------------------------------------------------------------
  // Organizations
  //---------------------------------------------------------------------------

  public getOrganizations(): Observable<Organization[]> {
    return this.http
      .get<Organization[]>(this.configsUrl + 'organizations')
      .pipe(
        map((response) =>
          response.sort((a, b) => a.name.localeCompare(b.name)),
        ),
      );
  }

  //---------------------------------------------------------------------------
  // Routings
  //---------------------------------------------------------------------------

  public getRoutings(): Observable<RouteDto[]> {
    return this.http.get<RouteDto[]>(this.configsUrl + 'routes');
  }

  public getRouteById(id: string): Observable<RouteDto> {
    const routeId = encodeURIComponent(id);
    return this.http.get<RouteDto>(this.configsUrl + 'routes/' + routeId);
  }

  public getPatternData(pattern: string): Observable<string[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params.append('pattern', pattern);
    return this.http
      .get<string[]>(`${this.configsUrl}routes/data`, {
        params,
      })
      .pipe(pluck('data')) as Observable<string[]>;
  }

  public createRouting(
    destination: string,
    routeInfo: { info: GeneralRouteInfo; output: unknown },
  ): Observable<RouteDto> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params.append('destination', destination);

    return this.http.post<RouteDto>(this.configsUrl + 'routes', routeInfo, {
      params,
    });
  }

  public updateRouting(
    routeId: string,
    destination: string,
    routeInfo: { info: GeneralRouteInfo; output: unknown },
  ) {
    const encodedId = encodeURIComponent(routeId);
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params.append('destination', destination);

    return this.http.put(`${this.configsUrl}routes/${encodedId}`, routeInfo, {
      params,
    });
  }

  public changeRouteActiveState(
    routeId: string,
    state: boolean,
  ): Observable<number> {
    const encodedId = encodeURIComponent(routeId);
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params.append('active', state.toString());

    return this.http.put<number>(
      `${this.configsUrl}routes/${encodedId}`,
      undefined,
      {
        params,
      },
    );
  }

  public deleteRouting(routeId: string): Observable<void> {
    const encodedId = encodeURIComponent(routeId);
    return this.http.delete<void>(this.configsUrl + 'routes/' + encodedId);
  }

  public updateLockRouting(
    routeId: string,
    is_locked: boolean,
  ): Observable<void> {
    const encodedId = encodeURIComponent(routeId);
    return this.http.post<void>(`${this.configsUrl}routes/${encodedId}/lock`, {
      is_locked,
    });
  }

  //---------------------------------------------------------------------------
  // Sites
  //---------------------------------------------------------------------------

  public getSites(): Observable<SiteTag[]> {
    return this.http.get<SiteTag[]>(this.configsUrl + 'project_tags');
  }

  public getSiteByTag(tagId: string): Observable<SiteTag> {
    return this.http.get<SiteTag>(this.configsUrl + 'project_tags/' + tagId);
  }

  public createSite(site: SiteInfo): Observable<SiteTag> {
    return this.http.post<SiteTag>(this.configsUrl + 'sites', site);
  }

  public updateSite(site: SiteInfo): Observable<SiteTag> {
    return this.http.put<SiteTag>(
      this.configsUrl + 'sites/' + site.tag_id,
      site,
    );
  }

  public updateSiteAlert(
    siteId: string,
    notificationConfig: SiteNotificationConfig,
  ): Observable<void> {
    return this.http.put<void>(
      this.configsUrl + `sites/${siteId}/alert`,
      notificationConfig,
    );
  }

  //---------------------------------------------------------------------------
  // Sources
  //---------------------------------------------------------------------------

  public getSources(): Observable<Source[]> {
    return this.http.get<Source[]>(`${this.configsUrl}sources`);
  }

  //---------------------------------------------------------------------------
  // Users
  //---------------------------------------------------------------------------

  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.configsUrl}users`);
  }

  public createUser(userInfo: UserInfo): Observable<User> {
    return this.http.post<User>(`${this.configsUrl}users`, userInfo);
  }

  public updateUser(userInfo: UserInfo): Observable<User> {
    const encodedMail = encodeURIComponent(userInfo.user_email);
    return this.http.put<User>(
      `${this.configsUrl}users/${encodedMail}`,
      userInfo,
    );
  }

  public updateUserAlarm(
    id: string,
    alarm: UserAlertInfo,
    site: string,
  ): Observable<User> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    params = params.append('site', site);
    return this.http.put<User>(`${this.configsUrl}users/${id}/alerts`, alarm, {
      params,
    });
  }

  public deleteUser(email: string): Observable<void> {
    const encodedMail = encodeURIComponent(email);
    return this.http.delete<void>(`${this.configsUrl}users/${encodedMail}`);
  }
}
