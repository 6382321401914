import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { CustomErrorStateMatcher } from 'src/app/services/util/formErrorStateMatcher';
import { Interface, RouteDto } from 'src/models/data-routing.models';
import { DevicesService } from '../../../../services/http/devices.service';
import { DeviceHierarchyStoreService } from '../../../../services/state/data/device-hierarchy/device-hierarchy-store.service';
import { MQTTTopicHelpDialogComponent } from './mqtt-topic-help-dialog/mqtt-topic-help-dialog.component';
import { RoutingCreationService } from './routing-creation.service';
@UntilDestroy()
@Component({
  selector: 'app-routing-creation',
  templateUrl: './routing-creation.component.html',
  styleUrls: ['./routing-creation.component.scss'],
})
export class RoutingCreationComponent implements OnInit {
  isEditMode: boolean;
  // interface state
  activeStepConfig = this.routingCreation.activeStep$;
  errorMatcher = new CustomErrorStateMatcher();
  activeStepIndex: number;

  // user input
  stepOneForm = new FormGroup({
    name: new FormControl('', Validators.required),
    is_active: new FormControl(true, Validators.required),
  });
  stepTwoForm = new FormGroup({
    pattern: new FormControl('', Validators.required),
    projectId: new FormControl('', Validators.required),
  });
  stepThreeForm = new FormGroup({
    destinationType: new FormControl('', Validators.required),
    output: new FormControl('', [Validators.required, Validators.minLength(1)]),
  });

  // data
  routeToEdit: RouteDto;
  interfaces$ = this.interfacesByProject$;
  exportedData$ = this.routingCreation.routeData$;

  loader$ = combineLatest([
    this.deviceHierarchy.loadDeviceHierarchy(),
    this.dataLoader.loadInterfaces(),
  ]).pipe(first(), shareReplay(1));

  constructor(
    private routingCreation: RoutingCreationService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private data: DataStoreService,
    private deviceHierarchy: DeviceHierarchyStoreService,
    private devHttp: DevicesService,
    private dataLoader: DataLoaderService,
  ) {
    this.isEditMode = this.route.snapshot.url[0].path === 'edit';
  }

  loadCondition(obss) {
    return !!obss?.length;
  }

  ngOnInit(): void {
    this.loader$.pipe(untilDestroyed(this)).subscribe();
    this.handleQueryParamChanges$.pipe(untilDestroyed(this)).subscribe();
    this.handlePatternChanges$.pipe(untilDestroyed(this)).subscribe();

    if (this.isEditMode) {
      this.devHttp
        .getRouteById(decodeURIComponent(this.route.snapshot.params.id))
        .pipe(untilDestroyed(this))
        .pipe(first())
        .subscribe((route) => {
          this.routeToEdit = route;

          this.stepOneForm.patchValue({
            name: route?.name ?? '',
            is_active: route?.is_active ?? true,
          });

          this.stepTwoForm.patchValue({
            pattern: route?.pattern ?? '',
            projectId: route?.pattern?.split('/')[1] ?? '',
          });

          this.routingCreation.updateRouteData(route.pattern);
        });
    }
  }

  private get handleQueryParamChanges$(): Observable<number> {
    return this.route.queryParams.pipe(
      map((params) => parseInt(params.step, 10)),
      tap((step) => (this.activeStepIndex = step)),
      tap((step) => this.routingCreation.setActiveStep(step)),
      filter((step) => !this.stepValidator(step - 1)),
      tap(() =>
        this.router.navigate([], {
          queryParams: { step: 1 },
          queryParamsHandling: 'merge',
        }),
      ),
    );
  }

  private get handlePatternChanges$(): Observable<string> {
    return (
      this.stepTwoForm.get('pattern') as UntypedFormControl
    ).valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap((pattern: string) => this.routingCreation.updateRouteData(pattern)),
      tap((pattern) =>
        (this.stepTwoForm.get('projectId') as UntypedFormControl).setValue(
          pattern.split('/')[1],
        ),
      ),
    );
  }

  private get interfacesByProject$(): Observable<Interface[]> {
    return this.data.interfaces$.pipe(
      map((interfaces) =>
        interfaces
          ? interfaces.filter((interf) =>
              this.stepTwoForm.value.projectId
                ? interf.tags.includes(this.stepTwoForm.value.projectId)
                : false,
            )
          : [],
      ),
    );
  }

  public stepValidator(step = this.activeStepIndex): boolean {
    switch (step) {
      case 1:
        return this.stepOneForm.valid;
      case 2:
        return this.stepTwoForm.valid;
      case 3:
        return (
          this.stepOneForm.valid &&
          this.stepTwoForm.valid &&
          this.stepThreeForm.valid
        );

      default:
        return false;
    }
  }

  public submitForm(): void {
    const destinationType = this.stepThreeForm.value.destinationType ?? '';
    const output = this.stepThreeForm.value.output ?? '';
    const name = this.stepOneForm.value.name ?? '';
    const is_active = !!this.stepOneForm.value.is_active;
    const pattern = this.stepTwoForm.value.pattern ?? '';
    const projectId = this.stepTwoForm.value.projectId ?? '';

    const routeInfo = {
      info: {
        name,
        is_active,
        pattern,
        projectId,
      },
      output: output,
    };
    if (this.routeToEdit) {
      this.dataLoader.updateExportRoute(
        this.routeToEdit.id,
        destinationType,
        routeInfo,
      );
    } else {
      this.dataLoader.createExportRoute(destinationType, routeInfo);
    }
  }

  public openMqttHelp(): void {
    this.dialog.open(MQTTTopicHelpDialogComponent);
  }
}
