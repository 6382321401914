import { Component, Input } from '@angular/core';
import { DeviceDto } from 'src/models/device.models';

@Component({
  selector: 'app-detail-stepper',
  templateUrl: './detail-stepper.component.html',
  styleUrls: ['./detail-stepper.component.scss'],
})
export class DetailStepperComponent {
  @Input() device: DeviceDto;

  constructor() {}

  public getSortedObjectKeys(obj: object): string[] {
    return Object.keys(obj)
      .filter((key) => !key.startsWith('_'))
      .sort((a: string, b: string) => a.localeCompare(b));
  }

  public isIOTcoreDevice(deviceSource: string): boolean {
    return deviceSource.toLowerCase() === 'iot_core';
  }

  public isTimeStamp(key: string): boolean {
    return key === 'created_at' || key === 'updated_at';
  }

  public replaceUnderScore(key: string): string {
    return key.replace('_', ' ');
  }
}
