<div class="single-parser">
  <mat-expansion-panel class="tutorial" expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title> Quick Reference </mat-panel-title>
    </mat-expansion-panel-header>
    <h4>Parser</h4>
    <p>
      Every line in the parser will take as many bits as you specify from the
      application payload of a packet.
    </p>
    <p></p>
    <h5>Example</h5>
    <p>You have 3 lines in your parser:</p>
    <p>1. 1 bit</p>
    <p>2. 7 bits</p>
    <p>3. 8 bits</p>
    <p></p>
    <p>
      Provided with a payload of 0x8322 (0b1000001100100010), they will take the
      following bits from the payload:
    </p>
    <p>1. 0b1</p>
    <p>2. 0b0000011</p>
    <p>3. 0b00100010</p>
    <p></p>
    <h5>Data types Boolean</h5>
    <p>
      The boolean type will transform any amount of bits that is not 0 into true
      otherwise it will transform it to false.
    </p>
    <p></p>
    <h5>Data types Integer</h5>
    <p>The integer type will return integer numbers. It has 2 options:</p>
    <ul>
      <li>
        Little - Check this if the number's endianness is little. Only supports
        16 and 32 bit integers.
      </li>
      <li>
        Signed - Check this if the number can be negative. If checked, a
        selection for the complement is available. 1's complement is the
        default.
      </li>
    </ul>
    <h5>Data types Float</h5>
    <p>
      The float type returns floating point numbers. Only supports 32 bit
      values. Check Little if the number is represented as little endian.
    </p>
    <p></p>
    <h4>Calculations</h4>
    <p></p>
    <p>
      After initially parsing the binary payload into a more structured form,
      you can specify formulas that further transform your data into your
      desired output.
    </p>
    <p></p>
    <h5>Formula</h5>
    <p>Enter a mathematical formula here.</p>
    <p>Supported operators:</p>
    <ul>
      <li>Basic arithmetic: + - * /</li>
      <li>Basic comparison: == &gt; &lt;</li>
      <li>
        Complete syntax
        <a
          href="https://mathjs.org/docs/expressions/syntax.html#operators"
          target="_blank"
          >here</a
        >
      </li>
    </ul>
    <p>
      You can refer to all variables that were specified in lines before the
      current one.
    </p>
    <p></p>
    <h4>Target variables</h4>
    <p>
      Every line in both Parser and Calculations puts the output value into a
      single JSON object.
    </p>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Parser </mat-panel-title>
    </mat-expansion-panel-header>

    <button
      mat-mini-fab
      color="primary"
      (click)="addParserElement()"
      *ngIf="!parserElements.controls.length"
    >
      <mat-icon>add</mat-icon>
    </button>

    <ng-container [formGroup]="form">
      <ng-container
        *ngFor="let parserElementForm of parserElements.controls; let i = index"
      >
        <div [formGroup]="parserElementForm" class="parser-element-form">
          <span
            >{{ parserElementBitRanges[i]?.min }} -
            {{ parserElementBitRanges[i]?.max }}</span
          >
          <mat-form-field appearance="outline">
            <mat-label>Bits</mat-label>
            <input matInput formControlName="bits" type="number" />
            <mat-error
              *ngIf="parserElementForm.get('bits')!.hasError('pattern')"
            >
              Bits can only contain numbers
            </mat-error>
          </mat-form-field>
          <div class="type-input-box">
            <mat-form-field appearance="outline">
              <mat-label>Type</mat-label>
              <mat-select formControlName="type">
                <mat-option value="int">Int</mat-option>
                <mat-option value="boolean">Boolean</mat-option>
                <mat-option value="string">String</mat-option>
                <mat-option value="float">Float</mat-option>
                <mat-option value="hex">Hexadecimal</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="type-input-supp">
              <mat-checkbox
                formControlName="littleEndian"
                color="primary"
                *ngIf="showLittle(parserElementForm.value)"
              >
                Little
              </mat-checkbox>
              <mat-checkbox
                formControlName="signed"
                color="primary"
                *ngIf="showSigned(parserElementForm.value)"
              >
                Signed
              </mat-checkbox>
              <mat-form-field
                appearance="filled"
                *ngIf="showComplement(parserElementForm.value)"
              >
                <mat-select formControlName="signComplement">
                  <mat-option value="1">Ones Complement</mat-option>
                  <mat-option value="2">Twos Complement</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <mat-form-field appearance="outline">
            <mat-label>Target variable</mat-label>
            <input matInput formControlName="target" />
            <mat-error
              *ngIf="parserElementForm.get('target')!.hasError('pattern')"
            >
              Target variable can only contain numbers, lower case letters and
              underscores.
            </mat-error>
          </mat-form-field>
          <button mat-mini-fab color="primary" (click)="addParserElement()">
            <mat-icon>add</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="onRemoveParserElement(i)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div
          class="row-seperator"
          *ngIf="i < parserElements.controls.length - 1"
        ></div>
      </ng-container>
    </ng-container>
  </mat-expansion-panel>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title *ngIf="config_id !== '_switch'">
        Calculations
      </mat-panel-title>
      <mat-panel-title *ngIf="config_id === '_switch'">
        Single Parser Conditions
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p *ngIf="config_id === '_switch'">
      "port" can be used as a variable in the parser condition
    </p>
    <button
      mat-mini-fab
      color="primary"
      (click)="addCalculation()"
      *ngIf="!calculations.controls.length"
    >
      <mat-icon>add</mat-icon>
    </button>

    <ng-container [formGroup]="form">
      <ng-container
        *ngFor="let calculationForm of calculations.controls; let i = index"
      >
        <div [formGroup]="calculationForm" class="calculations-form">
          <mat-form-field appearance="outline">
            <mat-label *ngIf="config_id !== '_switch'">Formula</mat-label>
            <mat-label *ngIf="config_id === '_switch'">Condition</mat-label>
            <input matInput formControlName="formula" />
            <mat-error
              *ngIf="calculationForm.get('formula')!.hasError('pattern')"
            >
              Can only contain numbers, lower case letters, underscores and
              basic arithmetic symbols (* / + -).
            </mat-error>
            <mat-error
              *ngIf="calculationForm.get('formula').hasError('invalidFormula')"
            >
              Not a valid formula
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label *ngIf="config_id === '_switch'">Target parser</mat-label>
            <mat-label *ngIf="config_id !== '_switch'"
              >Target variable</mat-label
            >
            <input matInput formControlName="target" />
            <mat-error
              *ngIf="calculationForm.get('target')!.hasError('pattern')"
            >
              Target variable can only contain numbers, lower case letters and
              underscores.
            </mat-error>
          </mat-form-field>
          <button mat-mini-fab color="primary" (click)="addCalculation()">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-mini-fab color="primary" (click)="onRemoveCalculation(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div
          class="row-seperator"
          *ngIf="i < calculations.controls.length - 1"
        ></div>
      </ng-container>
    </ng-container>
  </mat-expansion-panel>
  <mat-expansion-panel
    class="tutorial"
    *ngIf="config_id !== '_switch'"
    expanded="true"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Output definition </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="output-tuto">
      <div>
        <p>
          Assigning types and units to variables is necessary if you want to
          index measurement values in time series databases and visualize with
          dashboarding tools.
        </p>
        <p>
          The list below is filled with target variables from both the Parser
          and Calculations section.
        </p>
        <p>
          You can remove variables and their corresponding unit field from the
          outputted message by deselecting them in the "Output Selection"
          column.
        </p>
      </div>
      <div class="preview-box">
        <button
          mat-mini-fab
          color="primary"
          (click)="openJsonPreview(jsonExampleTmpl)"
          matTooltip="preview example json"
        >
          <mat-icon>preview</mat-icon>
        </button>
      </div>
    </div>
    <p></p>
    <tr class="unit-table-row">
      <th id="order">Order</th>
      <th id="target-variable">Target variable</th>
      <th id="type">Type</th>
      <th id="unit">Unit</th>
      <th id="output-selection">Business Field Selection</th>
    </tr>
    <p class="empty-list-message" *ngIf="!sortedVariableValues.length">
      Sorry, nothing to display here.
    </p>
    <tr
      *ngFor="let varValue of sortedVariableValues; let i = index"
      class="unit-table-row"
    >
      <td>
        <div class="order-btns">
          <button
            mat-mini-fab
            color="primary"
            (click)="decreaseVariableOrderAtIndex(i)"
          >
            <mat-icon>expand_less</mat-icon>
          </button>
          <button
            mat-mini-fab
            color="primary"
            (click)="augmentVariableOrderAtIndex(i)"
          >
            <mat-icon>expand_more</mat-icon>
          </button>
        </div>
        {{ varValue.order }}
      </td>
      <td>
        {{ varValue.name }}
      </td>
      <td>
        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select
            (selectionChange)="updateVariableType($event.value, varValue.name)"
            [(value)]="varValue.measurement_type"
          >
            <mat-option
              *ngFor="let type of measurementTypeNames"
              [value]="type"
            >
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td>
        <mat-form-field appearance="outline">
          <mat-label>Unit</mat-label>
          <mat-select
            (selectionChange)="updateVariableUnit($event.value, varValue.name)"
            [value]="getSelectedUnit(varValue.measurement_type, varValue.unit)"
          >
            <mat-option
              *ngFor="
                let unit of getMeasurementTypeUnits(varValue.measurement_type)
              "
              [value]="unit"
            >
              {{ unit.sign }} - {{ unit.detail }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
      <td>
        <mat-checkbox
          [checked]="varValue.write_business_field"
          color="primary"
          (change)="updateVariableBusinessField($event.checked, varValue.name)"
        >
          Value
        </mat-checkbox>
        <mat-checkbox
          [checked]="varValue.write_business_field_unit"
          color="primary"
          (change)="
            updateVariableBusinessFieldUnit($event.checked, varValue.name)
          "
        >
          Unit
        </mat-checkbox>
      </td>
    </tr>
  </mat-expansion-panel>
  <app-blueprint-test
    *ngIf="parserCreation.hexBlueprint?.type === 'single'"
  ></app-blueprint-test>
</div>

<ng-template #jsonExampleTmpl>
  <pre><code>{{ jsonExample | json }}</code></pre>
</ng-template>
