<mat-tab-group>
  <mat-tab label="General">
    <div class="general">
      <p class="label">Device ID:</p>
      <p>{{ device.device_id }}</p>
      <p class="label">Manufacturer:</p>
      <p>{{ device.device_type.brand.brand_id }}</p>
      <p class="label">Model:</p>
      <p>{{ device.device_type.metadata.label }}</p>
      <p class="label">Data Source:</p>
      <p>{{ device.source_id | titlecase }}</p>
      <p class="label">Functions:</p>
      <div class="functions-box">
        <p
          class="device-function"
          *ngFor="let function of device.device_type.metadata.functions"
        >
          {{ function | titlecase }}
        </p>
      </div>
      <ng-container *ngIf="device.site?.metadata.userAccessFlags">
        <p class="label">Control Groups:</p>
        <div class="functions-box">
          <p class="control_group" *ngFor="let group of device.attribute_tags">
            {{ group.tag_id | titlecase }}
          </p>
        </div>
      </ng-container>
    </div>
  </mat-tab>
  <mat-tab label="Metadata">
    <div class="general">
      <ng-container *ngFor="let key of getSortedObjectKeys(device.metadata)">
        <p class="label" id="key">{{ replaceUnderScore(key) | titlecase }}:</p>
        <p *ngIf="isTimeStamp(key)">
          {{ device.metadata[key] | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ' }}
        </p>
        <p *ngIf="!isTimeStamp(key)">
          {{ device.metadata[key] }}
        </p>
      </ng-container>
      <p *ngIf="isIOTcoreDevice(device.source_id)" class="label">
        Device Region:
      </p>
      <p *ngIf="isIOTcoreDevice(device.source_id)">
        {{ device.device_region | titlecase }}
      </p>
    </div>
  </mat-tab>
</mat-tab-group>
