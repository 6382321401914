import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { map, tap } from 'rxjs/operators';
import {
  Calculation,
  DecodingConfiguration,
  Field,
  ParserElement,
  Variable,
} from 'src/models/device-type.models';
import { ParserCreationService } from './../parser-creation.service';

import { getExampleValue, measurementTypes } from '../parser-creation-helper';
import { formulaValidator } from './formula-validator.directive';

@Component({
  selector: 'app-single-parser-editor',
  templateUrl: './single-parser-editor.component.html',
  styleUrls: ['./single-parser-editor.component.scss'],
})
export class SingleParserEditorComponent implements OnInit {
  @Input() config: DecodingConfiguration;
  @Input() config_id: string;
  @Output() configModified = new EventEmitter<DecodingConfiguration>();
  parserElementBitRanges: Array<{ max: number; min: number }> = [];
  form = this.fb.group({
    parserElements: this.fb.array([
      this.fb.group({} as Required<ParserElement>),
    ]),
    calculations: this.fb.array([this.fb.group({} as Calculation)]),
  });
  variables: { [key: string]: Variable } = {};
  jsonExample;

  constructor(
    private fb: NonNullableFormBuilder,
    public dialog: MatDialog,
    public parserCreation: ParserCreationService,
  ) {
    this.form.controls.parserElements.clear();
    this.form.controls.calculations.clear();
  }

  ngOnInit(): void {
    this.autoFillForm();
    this.handleFormValueChanges.subscribe();
  }

  public emitConfig() {
    const config = { ...this.form.getRawValue(), variables: this.variables };
    this.configModified.emit(config);
  }

  public updateVariableType(newType: string, variableName: string) {
    this.variables[variableName].measurement_type = newType;
    this.emitConfig();
  }

  public updateVariableUnit(
    newUnit: { sign: string; type: string },
    variableName: string,
  ) {
    this.variables[variableName].unit = newUnit.sign;
    this.variables[variableName].type = newUnit.type;
    this.emitConfig();
  }

  public getSelectedUnit(measurement_type: string, unit_sign: string) {
    return this.getMeasurementTypeUnits(measurement_type).find(
      (unit) => unit.sign === unit_sign,
    );
  }

  public get measurementTypeNames(): string[] {
    return Object.keys(measurementTypes);
  }

  public getMeasurementTypeUnits(
    type,
  ): Array<{ sign: string; detail: string; type: string }> {
    return measurementTypes[type] || [];
  }

  public get sortedVariableValues() {
    return Object.values(this.variables).sort((a, b) => a.order - b.order);
  }

  public augmentVariableOrderAtIndex(index: number) {
    if (index < Object.keys(this.variables).length - 1) {
      const sortedVars = this.sortedVariableValues;
      this.variables[sortedVars[index].name].order += 1;
      this.variables[sortedVars[index + 1].name].order -= 1;
    }
    this.emitConfig();
  }

  public decreaseVariableOrderAtIndex(index: number) {
    if (index > 0) {
      const sortedVars = this.sortedVariableValues;
      this.variables[sortedVars[index].name].order -= 1;
      this.variables[sortedVars[index - 1].name].order += 1;
    }
    this.emitConfig();
  }

  public updateVariableBusinessField(selected: boolean, varName: string) {
    this.variables[varName].write_business_field = selected;
    this.emitConfig();
  }

  public updateVariableBusinessFieldUnit(selected: boolean, varName: string) {
    this.variables[varName].write_business_field_unit = selected;
    this.emitConfig();
  }

  public get parserElements() {
    return this.form.controls.parserElements;
  }

  public addParserElement(pE?: ParserElement) {
    const parserElementForm = this.fb.group({
      bits: [
        pE?.bits || 8,
        [Validators.required, Validators.pattern('^[0-9]+$')],
      ],
      type: [pE?.type || 'int', Validators.required],
      target: [
        pE?.target || '',
        [Validators.required, Validators.pattern('^[0-9a-z_]+$')],
      ],
      littleEndian: [pE?.littleEndian || false],
      signed: [pE?.signed || false],
      signComplement: [pE?.signComplement || '1'],
    });

    this.parserElements.push(parserElementForm);
  }

  public onRemoveParserElement(index: number) {
    this.parserElements.removeAt(index);
    this.updateParserElementBitRanges();
  }

  public showLittle(value: { [key: string]: string }) {
    return value.type === 'float' || value.type === 'int';
  }

  public showSigned(value: { [key: string]: string }) {
    return value.type === 'int';
  }

  public showComplement(value: { [key: string]: string }) {
    return value.signed;
  }

  public get calculations() {
    return this.form.controls.calculations;
  }

  public addCalculation(cal?: Calculation) {
    const calculationForm = this.fb.group({
      formula: [cal?.formula ?? '', [Validators.required, formulaValidator]],
      target: [
        cal?.target ?? '',
        [Validators.required, Validators.pattern('^[0-9a-z_]+$')],
      ],
    });

    this.calculations.push(calculationForm);
  }

  public onRemoveCalculation(index: number) {
    this.calculations.removeAt(index);
  }

  private get handleFormValueChanges() {
    return this.form.valueChanges.pipe(
      tap(() => {
        this.updateParserElementBitRanges();
      }),
      map(() => this.newVariableNames),
      tap((newVarNames) => {
        this.addVariables(newVarNames);
        this.removeUnusedVariables(newVarNames);
      }),
      tap(() => this.emitConfig()),
    );
  }

  private updateParserElementBitRanges() {
    const ranges: Array<{ max: number; min: number }> = [];
    let min = 0;
    let max = -1;

    this.parserElements.controls.forEach((ctrl, i, arr) => {
      if (i !== 0) min = ranges[i - 1].min + arr[i - 1].getRawValue().bits;
      max = max + ctrl.getRawValue().bits;
      ranges.push({ max: max, min: min });
    });

    this.parserElementBitRanges = ranges;
  }

  private get newVariableNames(): string[] {
    return [
      ...this.parserElements.getRawValue().map((pE) => pE.target),
      ...this.calculations.getRawValue().map((cal) => cal.target),
    ].filter((name) => !!name);
  }

  private addVariables(varNames: string[]): void {
    varNames.forEach((varName) => {
      if (!this.variables[varName]) {
        this.variables[varName] = {
          name: varName,
          type: '',
          measurement_type: '',
          unit: '',
          order: Object.keys(this.variables).length + 1,
          write_business_field_unit: true,
          write_business_field: true,
        };
      }
    });
  }

  private removeUnusedVariables(varNames: string[]): void {
    Object.values(this.variables).forEach((v) => {
      if (!varNames.includes(v.name) && !v.from_source) {
        delete this.variables[v.name];
        this.updateVariableOrderAfterRemoveAt(v.order);
      }
    });
  }

  private updateVariableOrderAfterRemoveAt(removed: number) {
    Object.values(this.variables).forEach((v) => {
      if (v.order > removed) {
        this.variables[v.name].order -= 1;
      }
    });
  }

  private autoFillForm() {
    const pE = this.config.parserElements;
    const clc = this.config.calculations;
    pE.length
      ? pE.forEach((pE) => this.addParserElement(pE))
      : this.addParserElement();
    clc.length
      ? clc.forEach((cal) => this.addCalculation(cal))
      : this.addCalculation();
    this.variables = this.config.variables;
  }

  get businessFields(): Field[] {
    const business_fields: Field[] = [];
    this.sortedVariableValues.forEach((variable: Variable) => {
      if (variable.write_business_field)
        business_fields.push(this.mapVariableToBusinessField(variable));
      if (variable.write_business_field_unit)
        business_fields.push(this.mapVariableToUnitBusinessField(variable));
    });
    return business_fields;
  }

  get buildJsonExample() {
    const res = {};
    this.businessFields.forEach(
      (field) => (res[field.name] = getExampleValue(field.type)),
    );
    return res;
  }

  mapVariableToBusinessField(variable: Variable): Field {
    return {
      name: variable.name,
      type: variable.type as 'number' | 'string' | 'boolean',
    };
  }

  mapVariableToUnitBusinessField(variable: Variable): Field {
    return { name: variable.name + '_unit', type: 'string' };
  }

  openJsonPreview(templateRef: TemplateRef<unknown>) {
    this.jsonExample = this.buildJsonExample;
    this.dialog
      .open(templateRef)
      .afterClosed()
      .subscribe(() => (this.jsonExample = undefined));
  }
}
