import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { columnTitles } from './columns.config';

@Component({
  selector: 'app-sheet-data-builder',
  templateUrl: './sheet-data-builder.component.html',
  styleUrls: ['./sheet-data-builder.component.scss'],
})
export class SheetDataBuilderComponent implements OnInit {
  @Input() dataStream: Observable<string>;
  @Input() columns?: string[];
  @Input() tableName?: string;
  @Output() dataAssigned = new EventEmitter<string[]>();
  colTitles = columnTitles;
  dataOrderForm = new UntypedFormGroup({
    data: new UntypedFormArray([
      new UntypedFormControl('_received_timestamp'),
      new UntypedFormControl('device_id'),
      new UntypedFormControl(''),
      new UntypedFormControl(''),
      new UntypedFormControl(''),
    ]),
  });

  constructor() {}

  ngOnInit(): void {
    this.setFormValueWhenDataSelected.subscribe();
    this.emitFormValueChanges.subscribe();
    if (this.columns) {
      const dataControl = <UntypedFormArray>this.dataOrderForm.controls.data;
      dataControl.removeAt(0);
      dataControl.removeAt(1);
      this.columns.forEach((data, i) => {
        dataControl.setControl(i, new UntypedFormControl(data));
      });
    }
  }

  public get dataArray(): UntypedFormArray {
    return this.dataOrderForm.get('data') as UntypedFormArray;
  }

  addColumn() {
    const control = new UntypedFormControl('');
    this.dataArray.push(control);
  }

  private get setFormValueWhenDataSelected(): Observable<string> {
    return this.dataStream.pipe(
      tap((data) =>
        this.dataArray.controls.find((ctrl) => !ctrl.value)?.setValue(data),
      ),
    );
  }

  private get emitFormValueChanges(): Observable<string[]> {
    return this.dataOrderForm.valueChanges.pipe(
      map((value) => value.data.filter((column) => column.length)),
      tap((data) => this.dataAssigned.emit(data)),
    );
  }
}
