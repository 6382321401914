import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import {
  filter,
  first,
  map,
  mergeMap,
  pairwise,
  switchMap,
  tap,
} from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { DevicesService } from 'src/app/services/http/devices.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { environment } from 'src/environments/environment';
import { RouteDto } from 'src/models/data-routing.models';
import { AuthService } from './../../../../services/state/auth/auth.service';
import { DataLoaderService } from './../../../../services/state/data/data-loader.service';

@UntilDestroy()
@Component({
  selector: 'app-routing-details',
  templateUrl: './routing-details.component.html',
  styleUrls: ['./routing-details.component.scss'],
})
export class RoutingDetailsComponent implements OnInit {
  routeId: string;
  displayedData: string[] = [];
  googleSheetUrl: string;
  routeActive = new FormControl();
  destinationLabel: string;

  routing$: Observable<RouteDto>;
  waitForUpdate$ = new BehaviorSubject(false);
  isUpdateActionDisabled$: Observable<boolean>;
  isUnlockDisabled$: Observable<boolean>;
  tooltipUnlock$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private deviceHttp: DevicesService,
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    public auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.routeId = decodeURIComponent(
      this.route.snapshot.paramMap.get('id') as string,
    );

    this.routing$ = this.data.routing$.pipe(
      filter((route) => route !== undefined),
      map((route) => route as RouteDto), // avoid TS error
      tap(() => this.waitForUpdate$.next(false)),
    );

    this.isUpdateActionDisabled$ = combineLatest([
      this.routing$,
      this.waitForUpdate$,
    ]).pipe(
      map(([routing, waitForUpdate]) => routing.is_locked || waitForUpdate),
    );

    this.isUpdateActionDisabled$
      .pipe(untilDestroyed(this))
      .subscribe((isUpdateActionDisabled) => {
        if (isUpdateActionDisabled) {
          this.routeActive.disable();
        } else {
          this.routeActive.enable();
        }
      });

    this.isUnlockDisabled$ = combineLatest([
      this.routing$,
      this.auth.userEmail$,
      this.waitForUpdate$,
    ]).pipe(
      map(
        ([routing, userEmail, waitForUpdate]) =>
          waitForUpdate ||
          (routing.locked_by !== userEmail && !this.auth.isAdmin()),
      ),
    );

    this.tooltipUnlock$ = combineLatest([
      this.routing$,
      this.auth.userEmail$,
    ]).pipe(
      map(([routing, userEmail]) =>
        routing.locked_by !== userEmail && !this.auth.isAdmin()
          ? `this routing is locked by: ${routing.locked_by}`
          : 'unlock this routing',
      ),
    );

    this.dataLoader.loadDevices().subscribe();
    this.dataLoader.loadSites().subscribe();
    this.dataLoader.loadRouting(this.routeId).subscribe();

    this.routing$.pipe(first()).subscribe((route) => {
      this.destinationLabel = `${route.destination_name} (${route.destination_type})`;
      // TODO: check this code, concatenation of non-existing value will result in the string "undefined"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.googleSheetUrl = `${environment.googleSheetBaseUrl}${(route as any)?.info?.spreadsheet_id}`;
    });

    this.routing$
      .pipe(
        untilDestroyed(this),
        map((route) => route.is_active),
        tap((is_active) => this.routeActive.setValue(is_active)),
      )
      .subscribe();

    this.routeActive.valueChanges
      .pipe(
        untilDestroyed(this),
        pairwise(),
        filter(
          ([oldValue, newValue]) =>
            oldValue !== null && newValue !== null && oldValue !== newValue,
        ),
        tap(() => this.waitForUpdate$.next(true)),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        mergeMap(([oldValue, newValue]) =>
          this.dataLoader.changeRouteActiveState(this.routeId, newValue),
        ),
      )
      .subscribe();
  }

  public closeDetails(): void {
    this.router.navigateByUrl('home/routing');
  }

  public dataSelected(data: string[]): void {
    this.displayedData = data;
  }

  public deleteRoute(): void {
    const confirmationMessage = 'This action will delete the route definitely!';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmationMessage,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((answer) => !!answer),
        tap(() => this.waitForUpdate$.next(true)),
        switchMap(() => this.deviceHttp.deleteRouting(this.routeId)),
        tap(() => this.data.deleteRoute(this.routeId)),
        tap(() => this.router.navigateByUrl('/home/routing')),
      )
      .subscribe();
  }

  public editRoute(): void {
    this.waitForUpdate$.next(true);
    this.router.navigate(
      ['/home/routing/edit', encodeURIComponent(this.routeId)],
      { queryParams: { step: 1 } },
    );
  }

  public updateLockRoute(is_locked: boolean): void {
    const confirmationMessage = is_locked
      ? '<p>Locking this route will prevent anyone from updating or deleting it.<br>Only you and administrators will be able to unlock it afterwards.</p>'
      : '<p>Unlocking this route will allow anyone to update or delete it.</p>';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { html: confirmationMessage },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((answer) => !!answer),
        tap(() => this.waitForUpdate$.next(true)),
        switchMap(() =>
          this.dataLoader.updateLockRouting(this.routeId, is_locked),
        ),
      )
      .subscribe();
  }
}
