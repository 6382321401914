import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/state/auth/auth.service';
import { environment } from 'src/environments/environment';
import { Interface, RouteDto } from 'src/models/data-routing.models';
import { SiteTag } from '../../../../../../models/device.models';
import { DataLoaderService } from '../../../../../services/state/data/data-loader.service';
import { DataStoreService } from '../../../../../services/state/data/data-store.service';
import { routingDestinationTypes } from '../routing-creation.config';

@Component({
  selector: 'app-destination-form',
  templateUrl: './destination-form.component.html',
  styleUrls: ['./destination-form.component.scss'],
})
export class DestinationFormComponent implements OnInit {
  @Input() pattern: string;
  @Input() data: string[];
  @Input() interfaces: Interface[];
  @Input() formValues: RouteDto;
  @Input() projectId: string;
  shareAccount = environment.serviceAccountGoogleSheet;
  userAccessFlags = this.authService.claims?.accessFlags || [];
  interfaceControlGroups = new Set<string>();
  dataSelected$ = new Subject<string>();
  destinationTypesChoices = routingDestinationTypes;
  destinationType = new UntypedFormControl(null);
  destinationsChoices: { id: string; label: string }[] = [];
  destination = new UntypedFormControl(null, Validators.required);
  tab = new UntypedFormControl('', [Validators.maxLength(51)]);
  newSheetForm = new UntypedFormGroup({
    sheet_name: new UntypedFormControl('', Validators.required),
    sheet_id: new UntypedFormControl('', Validators.required),
    tab_name: new UntypedFormControl('', [Validators.maxLength(51)]),
    fields: new UntypedFormControl([], Validators.required),
  });
  newBigqueryForm = new UntypedFormGroup({
    dataset: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/[A-Za-z0-9_]{2,150}$/),
    ]),
    table: new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(/[A-Za-z0-9_]{2,150}$/),
    ]),
    fields: new UntypedFormControl([]),
  });
  gsServiceAccount = environment.gsExporterServiceAccount;

  datasetPrefix = `bq_ds_iothub_${environment.stage}_`.replace('-', '_');
  site: SiteTag | undefined;

  constructor(
    public authService: AuthService,
    private dataStoreService: DataStoreService,
    private dataLoader: DataLoaderService,
  ) {}

  ngOnInit(): void {
    this.resetFormsOnDestTypeChange.subscribe();
    this.sheetUrlValidator$.subscribe();
    if (this.formValues) {
      setTimeout(() => {
        this.destinationType.setValue(this.formValues.destination_type);
        this.destination.setValue(this.formValues.destination_id);
      });
    }

    const projectId = this.projectId;
    this.dataLoader.loadSites().subscribe((sites) => {
      this.site = sites?.find((site) => site?.tag_id === projectId);
      this.getDestinationChoices();
    });
  }

  public get formValidator(): boolean {
    let newInterfaceValid = true;
    if (this.newSheetSelected) {
      newInterfaceValid = this.newSheetForm.valid;
    }
    if (this.newBigQuerySelected) {
      newInterfaceValid = this.newBigqueryForm.valid;
    }
    return (
      this.destinationType.valid && this.destination.valid && newInterfaceValid
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get outputInfo(): { destinationType: string; output: any } {
    const outputInfo = {
      destinationType: this.destinationType.value,
      output: this.destination.value,
    };

    if (this.newSheetSelected) {
      outputInfo.output = {
        ...this.newSheetForm.value,
        sheet_id: this.getSheetIDfromUrl(this.newSheetForm.value.sheet_id),
        control_groups: Array.from(this.interfaceControlGroups),
      };
    }

    if (this.newBigQuerySelected) {
      const formValue = this.newBigqueryForm.value;
      formValue.dataset = `${this.datasetPrefix}${formValue.dataset}`;
      formValue.control_groups = Array.from(this.interfaceControlGroups);
      if (!formValue.fields.length) {
        delete formValue.fields;
      }
      outputInfo.output = formValue;
    }

    return outputInfo;
  }

  public getDestinationChoices(): void {
    const destType = this.destinationType.value;
    const destinations = this.interfaces
      .filter((int) => int.metadata.destination === destType)
      .map((int) => ({
        id: int.interface_id,
        label: int.metadata.name || int.interface_id,
      }));
    this.destinationsChoices = destinations;
    if (destType && destType === 'sheet') {
      this.destinationsChoices.push({
        id: `add new ${destType}`,
        label: `Add new ${destType}`,
      });
    }
  }

  public onFlagChange(checked: boolean, flag: string): void {
    checked
      ? this.interfaceControlGroups.add(flag)
      : this.interfaceControlGroups.delete(flag);
  }

  public getSheetIDfromUrl(url: string): string {
    const chunks = url.split('/');
    const idIndex =
      chunks.findIndex((chunk) => chunk.includes('spreadsheets')) + 2;
    return chunks[idIndex];
  }

  public get sheetUrlValidator$(): Observable<string> {
    return this.newSheetForm.controls.sheet_id.valueChanges.pipe(
      tap((url) => {
        if (!url.includes('google') || !url.includes('spreadsheets/')) {
          this.newSheetForm.controls.sheet_id.setErrors({ url: true });
        } else {
          this.newSheetForm.controls.sheet_id.setErrors(null);
        }
      }),
    );
  }

  public get sheets(): Interface[] {
    return this.interfaces.filter(
      (int) => int.metadata.destination === 'sheet',
    );
  }

  public get bigqueryInterfaces(): Interface[] {
    return this.interfaces.filter(
      (int) => int.metadata.destination === 'bigquery',
    );
  }

  public get resetFormsOnDestTypeChange(): Observable<string | null> {
    return this.destinationType.valueChanges.pipe(
      startWith(this.destinationType.value),
      tap((type) =>
        type ? this.destination.enable() : this.destination.disable(),
      ),
      tap(() => {
        this.destination.setValue(null);
        this.destination.markAsUntouched();
      }),
      tap(() => this.newSheetForm.markAsUntouched()),
      tap(() => this.interfaceControlGroups.clear()),
    );
  }

  public get newSheetSelected(): boolean {
    return this.destination.value === 'add new sheet';
  }

  public gsUpdateAllowed(inter: Interface): boolean {
    return (
      this.destinationType.value === 'sheet' &&
      this.destination.value === inter.interface_id
    );
  }

  public getColumns(sheetInterface: Interface): string[] {
    const fields = sheetInterface.metadata.fields;
    const defaultFields = ['', '', '', '', ''];
    return fields ? fields.split('#') : defaultFields;
  }

  public get newBigQuerySelected(): boolean {
    return this.destination.value === 'add new bigquery';
  }

  public dataSelected(data: string) {
    this.dataSelected$.next(data);
  }

  public setNewSheetFields(data: string[]) {
    this.newSheetForm.get('fields')?.setValue(data);
  }

  public setNewBigqueryFields(data: string[]) {
    this.newBigqueryForm.get('fields')?.setValue(data);
  }

  private getCurrentInterface() {
    return this.interfaces.filter(
      (int) => int.interface_id === this.destination.value,
    )?.[0];
  }
}
