<div class="wrapper" *appLoader="routing">
  <header class="mat-elevation-z4" [ngClass]="{ inactive: !routing.is_active }">
    <div class="title-box">
      <h3 class="title">{{ routing.name }}</h3>
      <div class="action-box">
        <span class="slide-label-left">Paused</span>
        <mat-slide-toggle
          *ngIf="auth.isMaintainer()"
          color="primary"
          class="margin-right"
          [formControl]="routeActive"
          >Active</mat-slide-toggle
        >
        <button
          mat-icon-button
          class="action-btn"
          (click)="editRoute()"
          *ngIf="auth.isMaintainer()"
          matTooltipPosition="below"
          matTooltip="edit this routing"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          class="action-btn"
          (click)="deleteRoute()"
          *ngIf="auth.isMaintainer()"
          matTooltipPosition="below"
          matTooltip="delete this routing"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <button
          mat-icon-button
          class="action-btn"
          (click)="closeDetails()"
          matTooltipPosition="below"
          matTooltip="close routing details"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
    <div class="columns">
      <div>
        <div class="pattern-box">
          <div class="round-icon-box mat-elevation-z3">
            <mat-icon svgIcon="custom-infos" class="blue-icon"></mat-icon>
          </div>
          <p class="pattern">{{ routing.pattern }}</p>
        </div>
        <div class="route-stats">
          <div class="route-status" *ngIf="!routing.is_active">
            <div class="round-icon-box mat-elevation-z3">
              <mat-icon
                svgIcon="custom-routing-paused"
                class="blue-icon"
              ></mat-icon>
            </div>
            <p>Paused</p>
          </div>
          <div class="route-status" *ngIf="routing.is_active">
            <div class="round-icon-box mat-elevation-z3">
              <mat-icon
                svgIcon="custom-routing-active"
                class="blue-icon"
              ></mat-icon>
            </div>
            <p>Active</p>
          </div>
          <app-counter-chip
            [label]="'Total Device'"
            [count]="routing.devices_number"
          ></app-counter-chip>
          <app-counter-chip
            [label]="'Total Data'"
            [count]="routing.data_number"
          ></app-counter-chip>
          <app-counter-chip [label]="destinationLabel"></app-counter-chip>
        </div>
      </div>
      <div>
        <p *ngIf="routing?.created_at" class="timestamp">
          <span>created at:</span>
          {{ routing?.created_at | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ' }}
        </p>
        <p *ngIf="routing?.updated_at" class="timestamp">
          <span>updated at:</span
          >{{ routing?.updated_at | date: 'yyyy-MM-ddTHH:mm:ssZZZZZ' }}
        </p>
      </div>
    </div>
  </header>
  <section class="data-selection mat-elevation-z3">
    <app-device-data-selection
      [routedDevices]="routing.devices"
      (deviceDataSelected)="dataSelected($event)"
    ></app-device-data-selection>
    <div class="destination">
      <a
        href="{{ googleSheetUrl }}"
        target="blank"
        class="dest-img"
        [ngClass]="{ 'disabled-link': routing.destination_type !== 'sheet' }"
      >
        <p>{{ routing.destination_type }}</p>
      </a>
    </div>
  </section>
  <section class="data-list">
    <app-data-list [displayedData]="displayedData" [routing]="routing"></app-data-list>
  </section>
</div>
