import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { DevicesService } from 'src/app/services/http/devices.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { environment } from 'src/environments/environment';
import { RouteDto } from 'src/models/data-routing.models';
import { AuthService } from './../../../../services/state/auth/auth.service';
import { DataLoaderService } from './../../../../services/state/data/data-loader.service';

@UntilDestroy()
@Component({
  selector: 'app-routing-details',
  templateUrl: './routing-details.component.html',
  styleUrls: ['./routing-details.component.scss'],
})
export class RoutingDetailsComponent {
  routeId: string;
  routing: RouteDto;
  displayedData: string[] = [];
  googleSheetUrl: string;
  routeActive: UntypedFormControl;
  destinationLabel: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private deviceHttp: DevicesService,
    private data: DataStoreService,
    private dataLoader: DataLoaderService,
    public auth: AuthService,
  ) {
    this._loadData();
  }

  private _loadData() {
    this.routeId = this.route.snapshot.paramMap.get('id') as string;
    this.dataLoader.loadDevices().subscribe();
    this.dataLoader.loadSites().subscribe();
    return this.deviceHttp
      .getRouteById(this.routeId)
      .pipe(first())
      .subscribe((route) => {
        this.routing = route;
        this.destinationLabel = `${route.destination_name} (${route.destination_type})`;
        this.routeActive = new UntypedFormControl(this.routing.is_active);

        this.routeActive.valueChanges
          .pipe(untilDestroyed(this))
          .subscribe((val) => this.changeRouteActiveState(val));

        // TODO: check this code, concatenation of non-existing value will result in the string "undefined"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.googleSheetUrl = `${environment.googleSheetBaseUrl}${(this.routing as any)?.info?.spreadsheet_id}`;
      });
  }

  public closeDetails(): void {
    this.router.navigateByUrl('home/routing');
  }

  public dataSelected(data: string[]): void {
    this.displayedData = data;
  }

  public deleteRoute(): void {
    const confirmationMessage = 'This action will delete the route definitely!';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: confirmationMessage,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((answer) => !!answer),
        switchMap(() => this.deviceHttp.deleteRouting(this.routing.id)),
        tap(() => this.data.deleteRoute(this.routing.id)),
        tap(() => this.router.navigateByUrl('/home/routing')),
      )
      .subscribe();
  }

  public editRoute(): void {
    this.router.navigate(
      ['/home/routing/edit', encodeURIComponent(this.routing.id)],
      { queryParams: { step: 1 } },
    );
  }

  public changeRouteActiveState(state: boolean): void {
    this.dataLoader
      .changeRouteActiveState(this.routing.id, state, this.routeId)
      .pipe(
        tap((routing) => {
          this.routing = routing;
        }),
      )
      .subscribe();
  }
}
