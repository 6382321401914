import {
  DecoderType,
  HexDecoderBluePrint,
  JsonDecoderBluePrint,
  MadatoryMessageField,
} from 'src/models/device-type.models';

export const getNewDecoderBluePrint = (type: DecoderType) => {
  if (type === 'multi' || type === 'single') {
    return {
      type: type,
      config: {
        _switch: newDecodingConfig(),
        _default: newDecodingConfig(),
        _single: newDecodingConfig(),
      },
    } as HexDecoderBluePrint;
  } else {
    return {
      type: type,
      config: { json_sample: '', data_fields: [] },
    } as JsonDecoderBluePrint;
  }
};

export const newDecodingConfig = () => {
  return {
    variables: { ...fireflyVars },
    parserElements: [],
    calculations: [],
  };
};

export const getExampleValue = (type: string): number | boolean | string => {
  switch (type) {
    case 'number':
      return Math.random();
    case 'string':
      return '';
    case 'boolean':
      return Math.random() < 0.5;
    default:
      return '';
  }
};

export const supportedValueTypes = [
  'string',
  'boolean',
  'bigint',
  'number',
  'object',
];

export const fireflyVars = {
  spreading_factor: {
    name: 'spreading_factor',
    type: 'number',
    measurement_type: 'basic',
    unit: 'N/A',
    order: 1,
    write_business_field_unit: false,
    write_business_field: true,
    from_source: true,
  },
  rssi: {
    name: 'rssi',
    type: 'number',
    measurement_type: 'signal quality',
    unit: 'dBm',
    order: 2,
    write_business_field_unit: false,
    write_business_field: true,
    from_source: true,
  },
  lsnr: {
    name: 'lsnr',
    type: 'number',
    measurement_type: 'signal quality',
    unit: 'dB',
    order: 3,
    write_business_field_unit: false,
    write_business_field: true,
    from_source: true,
  },
};

export const mandatoryFields: MadatoryMessageField[] = [
  {
    label: 'Device ID',
    key_name: 'device_id',
    description:
      'The Device ID is a unique key that identifies the device on the origin platform. It is mandatory!',
    valid: false,
    optional: false,
    allowed_types: ['string', 'number'],
  },
  {
    label: 'Timestamp',
    key_name: 'timestamp',
    description: 'Origin time of the device message. It is mandatory!',
    valid: false,
    optional: false,
    allowed_types: ['string', 'number'],
  },
  {
    label: 'Low Battery',
    key_name: 'low_battery',
    description:
      'Boolean value that reflects if the battery is critically low. It is optional but strongly recommended for device monitoring reasons!',
    valid: false,
    optional: true,
    allowed_types: ['boolean'],
  },
  {
    label: 'Battery percentage',
    key_name: 'battery_percent',
    description:
      'Numerical value that reflects the battery state of a device. It is optional but strongly recommended for device monitoring reasons!',
    valid: false,
    optional: true,
    allowed_types: ['string', 'number'],
  },
];

// TO DO: Move to database
export const measurementTypes = {
  basic: [
    {
      sign: '%',
      detail: 'Percent (0-100)',
      type: 'number',
    },
    {
      sign: '%h',
      detail: 'Percent humidity',
      type: 'number',
    },
    {
      sign: 'N/A',
      detail: 'Number',
      type: 'number',
    },
    {
      sign: 'N/A',
      detail: 'Boolean',
      type: 'boolean',
    },
    {
      sign: 'N/A',
      detail: 'String',
      type: 'string',
    },
  ],
  'signal quality': [
    {
      sign: 'dBm',
      detail: 'Decibel Milliwatts',
      type: 'number',
    },
    {
      sign: 'dB',
      detail: 'Decibel',
      type: 'number',
    },
  ],
  temperature: [
    {
      sign: '°C',
      detail: 'Deg. Celsius',
      type: 'number',
    },
    {
      sign: '°F',
      detail: 'Deg. Fahrenheit',
      type: 'number',
    },
    {
      sign: 'K',
      detail: 'Kelvin',
      type: 'number',
    },
  ],
  energy: [
    {
      sign: 'A',
      detail: 'Ampere',
      type: 'number',
    },
    {
      sign: 'mA',
      detail: 'Milliampere',
      type: 'number',
    },
    {
      sign: 'lx',
      detail: 'Lux',
      type: 'number',
    },
    {
      sign: 'lm',
      detail: 'Lumen',
      type: 'number',
    },
    {
      sign: 'dB',
      detail: 'Decibel',
      type: 'number',
    },
    {
      sign: 'mV',
      detail: 'Millivolt',
      type: 'number',
    },
    {
      sign: 'V',
      detail: 'Volt',
      type: 'number',
    },
    {
      sign: 'kV',
      detail: 'Kilovolt',
      type: 'number',
    },
    {
      sign: 'Wh',
      detail: 'Watt Hour',
      type: 'number',
    },
    {
      sign: 'kWh',
      detail: 'Kilowatt Hour',
      type: 'number',
    },
  ],
  power: [
    {
      sign: 'W',
      detail: 'Watt',
      type: 'number',
    },
    {
      sign: 'kW',
      detail: 'Kilowatt',
      type: 'number',
    },
    {
      sign: 'VA',
      detail: 'Voltampere',
      type: 'number',
    },
    {
      sign: 'kVA',
      detail: 'Kilo Voltampere',
      type: 'number',
    },
    {
      sign: 'Var',
      detail: 'Reactive Power',
      type: 'number',
    },
    {
      sign: 'kVar',
      detail: 'Reactive Power',
      type: 'number',
    },
    {
      sign: 'A/h',
      detail: 'Ampere Hour',
      type: 'number',
    },
    {
      sign: 'mA/h',
      detail: 'Milliampere Hour',
      type: 'number',
    },
  ],
  concentration: [
    {
      sign: 'ppm',
      detail: 'Parts-per-million',
      type: 'number',
    },
    {
      sign: 'g/l',
      detail: 'Grams per liter',
      type: 'number',
    },
    {
      sign: 'mg/l',
      detail: 'Milligrams per liter',
      type: 'number',
    },
    {
      sign: 'ug/l',
      detail: 'Micrograms per liter',
      type: 'number',
    },
  ],
  pressure: [
    {
      sign: 'Pa',
      detail: 'Pascal',
      type: 'number',
    },
    {
      sign: 'hPa',
      detail: 'Hecto Pascal',
      type: 'number',
    },
    {
      sign: 'kPa',
      detail: 'Kilo Pascal',
      type: 'number',
    },
    {
      sign: 'bar',
      detail: 'Bar',
      type: 'number',
    },
  ],
  volume: [
    {
      sign: 'l',
      detail: 'Liter',
      type: 'number',
    },
    {
      sign: 'm3',
      detail: 'Cubic Metre',
      type: 'number',
    },
  ],
  flow: [
    {
      sign: 'l/h',
      detail: 'Liter per Hour',
      type: 'number',
    },
    {
      sign: 'l/min',
      detail: 'Liter per Minute',
      type: 'number',
    },
    {
      sign: 'l/s',
      detail: 'Liter per Second',
      type: 'number',
    },
    {
      sign: 'm3/h',
      detail: 'Cubic Metre per Hour',
      type: 'number',
    },
  ],
  distance: [
    {
      sign: 'm',
      detail: 'Meter',
      type: 'number',
    },
    {
      sign: 'cm',
      detail: 'Centimeter',
      type: 'number',
    },
    {
      sign: 'mm',
      detail: 'Millimeter',
      type: 'number',
    },
  ],
  time: [
    {
      sign: 's',
      detail: 'Second',
      type: 'number',
    },
    {
      sign: 'min',
      detail: 'Minute',
      type: 'number',
    },
    {
      sign: 'h',
      detail: 'Hour',
      type: 'number',
    },
    {
      sign: 'd',
      detail: 'Day',
      type: 'number',
    },
  ],
  turbidity: [
    {
      sign: 'NTU',
      detail: 'Nephelometric Turbidity Unit',
      type: 'number',
    },
    {
      sign: 'FNU',
      detail: 'Formazin Nephelometric Units',
      type: 'number',
    },
    {
      sign: 'FTU',
      detail: 'Formazin Turbidity Units',
      type: 'number',
    },
    {
      sign: 'FAU',
      detail: 'Formazin Attenuation Units',
      type: 'number',
    },
    {
      sign: 'JTU',
      detail: 'Jackson Turbidity Unit',
      type: 'number',
    },
  ],
  other: [
    {
      sign: '1/m',
      detail: 'one per meter (Wave number)',
      type: 'number',
    },
    {
      sign: '1/cm',
      detail: 'one per Centimeter (Wave number)',
      type: 'number',
    },
  ],
};
