import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  shareReplay,
  tap,
} from 'rxjs/operators';
import { DataLoaderService } from 'src/app/services/state/data/data-loader.service';
import { DataStoreService } from 'src/app/services/state/data/data-store.service';
import { CustomErrorStateMatcher } from 'src/app/services/util/formErrorStateMatcher';
import { Interface, RouteDto } from 'src/models/data-routing.models';
import { DevicesService } from '../../../../services/http/devices.service';
import { DeviceHierarchyStoreService } from '../../../../services/state/data/device-hierarchy/device-hierarchy-store.service';
import { DestinationFormComponent } from './destination-form/destination-form.component';
import { MQTTTopicHelpDialogComponent } from './mqtt-topic-help-dialog/mqtt-topic-help-dialog.component';
import { RoutingCreationService } from './routing-creation.service';

@Component({
  selector: 'app-routing-creation',
  templateUrl: './routing-creation.component.html',
  styleUrls: ['./routing-creation.component.scss'],
})
export class RoutingCreationComponent implements OnInit {
  // interface state
  @ViewChild('destination') destForm: DestinationFormComponent;
  activeStepConfig = this.routingCreation.activeStep$;
  errorMatcher = new CustomErrorStateMatcher();
  activeStepIndex: number;

  // user input
  generalForm = this.routingCreation.generalRouteInfoForm();

  // data
  routeToEdit: RouteDto;
  interfaces$ = this.interfacesByProject$;
  exportedData$ = this.routingCreation.routeData$;

  loader$ = combineLatest([
    this.deviceHierarchy.loadDeviceHierarchy(),
    this.dataLoader.loadInterfaces(),
  ]).pipe(first(), shareReplay(1));

  constructor(
    private routingCreation: RoutingCreationService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private data: DataStoreService,
    private deviceHierarchy: DeviceHierarchyStoreService,
    private devHttp: DevicesService,
    private dataLoader: DataLoaderService,
  ) {
    this._loadData();
  }

  loadCondition(obss) {
    return !!obss?.length;
  }

  private _loadData() {
    this.loader$.subscribe();
  }

  ngOnInit(): void {
    this.handleQueryParamChanges$.subscribe();
    this.handlePatternChanges$.subscribe();

    const isEditMode = this.route.snapshot.url[0].path === 'edit';

    if (isEditMode) {
      this.devHttp
        .getRouteById(this.route.snapshot.params.id)
        .pipe(first())
        .subscribe((route) => {
          this.routeToEdit = route;
          this.generalForm = this.routingCreation.generalRouteInfoForm(route);
          this.routingCreation.updateRouteData(route.pattern);
        });
    }
  }

  private get handleQueryParamChanges$(): Observable<number> {
    return this.route.queryParams.pipe(
      map((params) => parseInt(params.step, 10)),
      tap((step) => (this.activeStepIndex = step)),
      tap((step) => this.routingCreation.setActiveStep(step)),
      filter((step) => !this.stepValidator(step - 1)),
      tap(() =>
        this.router.navigate([], {
          queryParams: { step: 1 },
          queryParamsHandling: 'merge',
        }),
      ),
    );
  }

  private get handlePatternChanges$(): Observable<string> {
    return (
      this.generalForm.get('pattern') as UntypedFormControl
    ).valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap((pattern: string) => this.routingCreation.updateRouteData(pattern)),
      tap((pattern) =>
        (this.generalForm.get('projectId') as UntypedFormControl).setValue(
          pattern.split('/')[1],
        ),
      ),
    );
  }

  private get interfacesByProject$(): Observable<Interface[]> {
    return this.data.interfaces$.pipe(
      map((interfaces) =>
        interfaces
          ? interfaces.filter((interf) =>
              interf.tags.includes(this.generalForm.value.projectId),
            )
          : [],
      ),
    );
  }

  public stepValidator(step = this.activeStepIndex): boolean {
    const stepOneValid = (this.generalForm.get('name') as UntypedFormControl)
      .valid;
    const stepTwoValid = (this.generalForm.get('pattern') as UntypedFormControl)
      .valid;
    const stepThreeValid = this.destForm ? this.destForm.formValidator : false;

    switch (step) {
      case 1:
        return stepOneValid;
      case 2:
        return stepTwoValid && stepOneValid;
      case 3:
        return stepThreeValid && stepTwoValid && stepOneValid;
      default:
        return false;
    }
  }

  public submitForm(): void {
    const outputInfo = this.destForm.outputInfo;
    const routeInfo = {
      info: this.generalForm.value,
      output: outputInfo.output,
    };
    if (this.routeToEdit) {
      this.dataLoader.updateExportRoute(
        this.routeToEdit.id,
        outputInfo.destinationType,
        routeInfo,
      );
    } else {
      this.dataLoader.createExportRoute(outputInfo.destinationType, routeInfo);
    }
  }

  public openMqttHelp(): void {
    this.dialog.open(MQTTTopicHelpDialogComponent);
  }
}
