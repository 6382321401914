export interface Interface {
  interface_id: string;
  topic: string;
  metadata: { [key: string]: string };
  tags: string[];
  data_format: number;
}

export interface Routing {
  pattern: string;
  interfaces: string[];
  active_interfaces: string[];
}

export interface RoutingDTO {
  id: string;
  pattern: string;
  interfaces: Interface[];
  active_interfaces: string[];
}

export interface RoutingAnswer {
  mainRoutes: RoutingDTO[];
  inheritedRoutes: RoutingDTO[];
}

export interface PubSubMessageValue {
  path: string;
  value: string | number | boolean;
  tags: string[];
  timestamp?: number;
  _received_timestamp: number;
  metadata?: { [key: string]: string | number | boolean };
}

export interface PubSubMessage {
  _device_id: string;
  _received_timestamp: number;
  _path: string;
  _tags: string[];
  timestamp?: number;
  [key: string]: unknown;
}

export type DestinationType =
  | 'mes'
  | 'sheet'
  | 'bigquery'
  | 'iot_core'
  | 'pubsub';

export enum Destinations {
  MES = 'mes',
  GOOGLE_SHEET = 'sheet',
  BIGQUERY = 'bigquery',
  IOT_CORE = 'iot_core',
  PUBSUB = 'pubsub',
}

export const destinationValues = (): string[] => {
  return Object.values(Destinations);
};

export interface RouteDto {
  id: string;
  name: string;
  pattern: string;
  is_active: boolean;
  destination_name: string;
  destination_type: DestinationType;
  destination_id: string;
  devices_number: number;
  data_number: number;
  updated_at?: number;
  created_at?: number;
  description?: string;
  devices?: DeviceLightDto[];
  info?: BigqueryOutputInfo | MesOutputInfo | SheetOutputInfo;
}

export interface BigqueryOutputInfo {
  dataset: string;
  table: string;
  fields?: string[];
  control_groups?: string[];
}

export interface SheetOutputInfo {
  sheet_name: string;
  sheet_id: string;
  fields?: string[];
  control_groups?: string[];
}

export interface MesOutputInfo {
  mes_name: string;
}

export interface SheetData {
  column: string;
  data: string;
}

export interface DeviceLightDto {
  device_id: string;
  device_name: string;
  hierarchy_prefix: string;
  data: string[];
}

export interface GeneralRouteInfo {
  name: string;
  pattern: string;
  projectId: string;
  is_active: boolean;
  description?: string;
}

export interface DefaultRouteInfo {
  info: GeneralRouteInfo;
  output: string;
}

export interface SheetRouteInfo {
  info: GeneralRouteInfo;
  output: string | SheetOutputInfo;
}

export interface BigqueryRouteInfo {
  info: GeneralRouteInfo;
  output: string | BigqueryOutputInfo;
}
