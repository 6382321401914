import { Injectable } from '@angular/core';
import { DevicesService } from 'src/app/services/http/devices.service';
import {
  DecoderBluePrint,
  DecoderType,
  DecodingConfiguration,
  HexDecoderBluePrint,
  JsonDecoderBluePrint,
  MessageField,
  ParsedJSON,
  Variable,
} from 'src/models/device-type.models';
import {
  getNewDecoderBluePrint,
  newDecodingConfig,
} from './parser-creation-helper';

@Injectable({
  providedIn: 'root',
})
export class ParserCreationService {
  private _protectedConfigs = ['_default', '_switch', '_single'];
  private _hexBlueprint: HexDecoderBluePrint | undefined;
  private _jsonBlueprint: JsonDecoderBluePrint | undefined;

  constructor(private http: DevicesService) {}

  public get hexBlueprint(): HexDecoderBluePrint | undefined {
    return this._hexBlueprint;
  }

  public resetBluePrints() {
    this._hexBlueprint = undefined;
    this._jsonBlueprint = undefined;
  }

  public jsonBlueprint(deleteUnused = false): JsonDecoderBluePrint | undefined {
    if (this._jsonBlueprint && deleteUnused) {
      return this.filterMessageFields(this._jsonBlueprint);
    } else {
      return this._jsonBlueprint;
    }
  }

  public setBlueprint(bp?: DecoderBluePrint): void {
    if (bp?.type === 'multi' || bp?.type === 'single') {
      this._hexBlueprint = bp as HexDecoderBluePrint;
    } else if (bp?.type === 'json' || bp?.type === 'passthrough') {
      this._jsonBlueprint = bp as JsonDecoderBluePrint;
    } else {
      this._jsonBlueprint = undefined;
      this._hexBlueprint = undefined;
    }
  }

  public setBlueprintType(type: DecoderType): void {
    if (type === 'multi' || type === 'single') {
      if (this._hexBlueprint) {
        this._hexBlueprint.type = type;
      } else {
        this.setBlueprint(getNewDecoderBluePrint(type));
      }
    } else {
      this.setBlueprint(getNewDecoderBluePrint(type));
    }
  }

  public setJsonBluePrintConfig(config: JsonDecoderBluePrint['config']) {
    if (this._jsonBlueprint) {
      this._jsonBlueprint.config = config;
    }
  }

  public setHexBluePrintConfig(
    id: string,
    config: DecodingConfiguration,
  ): void {
    if (this._hexBlueprint) {
      this._hexBlueprint.config[id] = config;
    }
    if (id === '_switch') {
      const singleParserIds = config.calculations.map((cal) => cal.target);
      this.updateSingleParserList(singleParserIds);
    }
  }

  public updateSingleParserList(ids: string[]) {
    ids.forEach((id) => {
      if (this._hexBlueprint && !this._hexBlueprint.config[id]) {
        this._hexBlueprint.config[id] = newDecodingConfig();
      }
    });
    Object.keys(this._hexBlueprint!.config).forEach((configId) => {
      if (
        !this._protectedConfigs.includes(configId) &&
        !ids.includes(configId)
      ) {
        delete this._hexBlueprint!.config[configId];
      }
    });
  }

  public get singleParserList(): { [key: string]: DecodingConfiguration } {
    return this._hexBlueprint!.config;
  }

  public get singleParserIds(): string[] {
    return Object.keys(this.singleParserList).filter(
      (id) => !this._protectedConfigs.includes(id),
    );
  }

  public get multiParserVariables(): Variable[] {
    const { _switch, _single, ...sp } = this.singleParserList;

    return ([] as Variable[]).concat(
      ...Object.values(sp).map((config) => Object.values(config.variables)),
    );
  }

  public get variables(): Variable[] {
    return this._hexBlueprint?.type === 'single'
      ? Object.values(this._hexBlueprint.config._single.variables)
      : this.multiParserVariables;
  }

  public decodeTestPayload(payload: string, port?: number) {
    return this.http.decodeTestPayload(payload, this._hexBlueprint!, port);
  }

  public get dataTags(): string[] {
    return Array.from(
      new Set(
        Object.values(this.variables).map(
          (variable) => variable.measurement_type,
        ),
      ),
    );
  }

  public mapJsonToMessageFields(json: ParsedJSON): MessageField[] {
    const fields: MessageField[] = [];

    const recursive = (obj: unknown, path = '') => {
      if (obj) {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
          const newPath = path.length ? `${path}/${key}` : key;
          const field = {
            name: newPath,
            type: typeof obj[key],
            output_name: newPath.replace(/[&\\#,+()$~%.'":*?@<>{}éèàç]/g, ''),
            duplicate_output: false,
            deleted: false,
          };

          if (
            obj[key] &&
            typeof obj[key] === 'object' &&
            !Array.isArray(obj[key])
          ) {
            recursive(obj[key], newPath);
          } else if (!Array.isArray(obj[key])) {
            fields.push(field);
          }
        });
      }
    };

    recursive(json);

    return fields;
  }

  public reconstructMapping(
    json?: string,
    mappingResult?: Pick<MessageField, 'name' | 'output_name' | 'type'>[],
  ): MessageField[] {
    if (!!json && !!mappingResult) {
      const mappingStart = this.mapJsonToMessageFields(JSON.parse(json));
      const mappedFields = mappingResult?.map((field) => ({
        ...field,
        deleted: false,
        duplicate_output: false,
      }));
      const deletedFields = mappingStart
        .filter(
          (field) =>
            !mappedFields?.find((resField) => resField.name === field.name),
        )
        .map((field) => ({ ...field, deleted: true }));

      return [...mappedFields!, ...deletedFields];
    } else {
      return [];
    }
  }

  private filterMessageFields(bluePrint: JsonDecoderBluePrint) {
    const res = { ...bluePrint };
    res.config.data_fields = res.config
      .data_fields!.filter((field: MessageField) => !field.deleted)
      .filter((field: MessageField) => !field.duplicate_output)
      .map((field: MessageField) => {
        const { deleted, duplicate_output, ...res } = field;
        return res;
      });
    return res;
  }
}
