import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/services/shared.module';
import { DefaultDeviceCreationComponent } from './device-creation/default-device-creation/default-device-creation.component';
import { DeviceCreationComponent } from './device-creation/device-creation.component';
import { FireflyDeviceCreationComponent } from './device-creation/firefly-device-creation/firefly-device-creation.component';
import { IotCoreDeviceCreationComponent } from './device-creation/iot-core-creation/iotcore-device-creation.component';
import { ModelChoiceComponent } from './device-creation/model-choice/model-choice.component';
import { DeviceTypeBannerComponent } from './device-creation/shared/device-type-banner/device-type-banner.component';
import { MetadataFormComponent } from './device-creation/shared/metadata-form/metadata-form.component';
import { SelectPositionMapComponent } from './device-creation/shared/select-position-map/select-position-map.component';
import { DetailStepperComponent } from './device-detail/detail-stepper/detail-stepper.component';
import { DeviceCalibrationFunctionComponent } from './device-detail/device-calibration-function/device-calibration-function.component';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { DeviceManagementComponent } from './device-management.component';
import { DeviceMoveConfirmationDialogComponent } from './device-move-confirmation-dialog/device-move-confirmation-dialog.component';
import { DevicePictureUpdateComponent } from './device-picture-update/device-picture-update.component';
import { DeviceProvidingModelSelectComponent } from './device-providing/device-providing-model-select/device-providing-model-select.component';
import { DeviceProvidingComponent } from './device-providing/device-providing.component';
import { DeviceUpdateComponent } from './device-update/device-update.component';
import { ActionButtonsComponent } from './hierarchy-node-list/action-buttons/action-buttons.component';
import { DeviceListElementComponent } from './hierarchy-node-list/device-list-element/device-list-element.component';
import { FolderListElementComponent } from './hierarchy-node-list/folder-list-element/folder-list-element.component';
import { HierarchyNodeListComponent } from './hierarchy-node-list/hierarchy-node-list.component';
import { NewFolderDialogComponent } from './hierarchy-node-list/new-folder-dialog/new-folder-dialog.component';
import { HierarchyTreeComponent } from './hierarchy-tree/hierarchy-tree.component';
import { MultiDownlinkComponent } from './multi-downlink/multi-downlink.component';

const COMPONENTS = [
  DeviceManagementComponent,
  HierarchyTreeComponent,
  ModelChoiceComponent,
  NewFolderDialogComponent,
  SelectPositionMapComponent,
  DeviceDetailComponent,
  MetadataFormComponent,
  HierarchyNodeListComponent,
  FolderListElementComponent,
  DeviceListElementComponent,
  ActionButtonsComponent,
  IotCoreDeviceCreationComponent,
  DeviceTypeBannerComponent,
  FireflyDeviceCreationComponent,
  DefaultDeviceCreationComponent,
  DeviceCreationComponent,
  DetailStepperComponent,
  DeviceProvidingComponent,
  DeviceProvidingModelSelectComponent,
  DeviceMoveConfirmationDialogComponent,
];

@NgModule({
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    RouterModule,
    MatSnackBarModule,
  ],
  declarations: [
    COMPONENTS,
    DeviceUpdateComponent,
    DevicePictureUpdateComponent,
    DeviceCalibrationFunctionComponent,
    MultiDownlinkComponent,
  ],
  exports: [COMPONENTS],
})
export class DeviceManagementModule {}
