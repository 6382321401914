import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from 'src/app/pages/main/dashboard/dashboard.component';
import { InterfaceManagementComponent } from 'src/app/pages/main/interface-management/interface-management.component';
import { AuthGuard } from 'src/app/services/state/auth/auth.guard';
import { techAdminGuard } from 'src/app/services/state/auth/tech-admin.guard';
import { RouterOutletComponent } from './components/router-outlet/router-outlet.component';
import { LoginComponent } from './pages/login/login.component';
import { LoginResolver } from './pages/login/login.resolver';
import { AlertingComponent } from './pages/main/alerting/alerting.component';
import { CatalogueComponent } from './pages/main/catalogue/catalogue.component';
import { ModelDetailComponent } from './pages/main/catalogue/model-detail/model-detail.component';
import { DefaultDeviceCreationComponent } from './pages/main/device-management/device-creation/default-device-creation/default-device-creation.component';
import { DeviceCreationComponent } from './pages/main/device-management/device-creation/device-creation.component';
import { FireflyDeviceCreationComponent } from './pages/main/device-management/device-creation/firefly-device-creation/firefly-device-creation.component';
import { IotCoreDeviceCreationComponent } from './pages/main/device-management/device-creation/iot-core-creation/iotcore-device-creation.component';
import { ModelChoiceComponent } from './pages/main/device-management/device-creation/model-choice/model-choice.component';
import { PathGuard } from './pages/main/device-management/device-creation/path.guard';
import { DeviceTypeSelectedGuard } from './pages/main/device-management/device-creation/type-selected.guard';
import { DeviceDetailComponent } from './pages/main/device-management/device-detail/device-detail.component';
import { DeviceManagementComponent } from './pages/main/device-management/device-management.component';
import { HierarchyNodeListComponent } from './pages/main/device-management/hierarchy-node-list/hierarchy-node-list.component';
import { MultiDownlinkComponent } from './pages/main/device-management/multi-downlink/multi-downlink.component';
import { GatewayImportComponent } from './pages/main/gateway-list/gateway-import/gateway-import.component';
import { GatewayListComponent } from './pages/main/gateway-list/gateway-list.component';
import { MainComponent } from './pages/main/main.component';
import { RoutingCreationComponent } from './pages/main/routing-list/routing-creation/routing-creation.component';
import { RoutingDetailsComponent } from './pages/main/routing-list/routing-details/routing-details.component';
import { RoutingListComponent } from './pages/main/routing-list/routing-list.component';
import { SiteComponent } from './pages/main/site/site.component';
import { UserCreationComponent } from './pages/main/user-management/user-creation/user-creation.component';
import { UserManagementComponent } from './pages/main/user-management/user-management.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { OperatorGuard } from './services/state/auth/oparator.guard';
import { writeRightsGuard } from './services/state/auth/write-rights.guard';

const resolver = [LoginResolver];

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    resolve: { isLogged: LoginResolver },
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'home',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'device',
        component: RouterOutletComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'manager',
            component: DeviceManagementComponent,
            canActivate: [AuthGuard],
            children: [
              {
                path: '',
                component: HierarchyNodeListComponent,
                resolve: HierarchyNodeListComponent.getResolver(),
                canActivate: [AuthGuard],
              },
            ],
          },
          {
            path: 'multi-downlink',
            component: MultiDownlinkComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'details/:id',
            component: DeviceDetailComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'new',
            component: DeviceCreationComponent,
            canActivate: [AuthGuard, writeRightsGuard],
            children: [
              {
                path: '',
                canActivate: [AuthGuard, PathGuard],
                component: ModelChoiceComponent,
                resolve: ModelChoiceComponent.getResolver(),
              },
              {
                path: 'firefly',
                canActivate: [AuthGuard, PathGuard, DeviceTypeSelectedGuard],
                component: FireflyDeviceCreationComponent,
              },
              {
                path: 'generic',
                canActivate: [AuthGuard, PathGuard, DeviceTypeSelectedGuard],
                component: DefaultDeviceCreationComponent,
              },
              {
                path: 'iotcore',
                canActivate: [AuthGuard, PathGuard, DeviceTypeSelectedGuard],
                component: IotCoreDeviceCreationComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'catalogue',
        component: RouterOutletComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: CatalogueComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'details/:id',
            component: ModelDetailComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'site',
        component: RouterOutletComponent,
        canActivate: [AuthGuard, techAdminGuard],
        children: [
          {
            path: '',
            component: SiteComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'interfaces',
        component: RouterOutletComponent,
        canActivate: [AuthGuard, techAdminGuard],
        children: [
          {
            path: '',
            component: InterfaceManagementComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'users',
        component: RouterOutletComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: UserManagementComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'new',
            component: UserCreationComponent,
            canActivate: [AuthGuard, OperatorGuard],
          },
          {
            path: 'edit',
            component: UserCreationComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'routing',
        component: RouterOutletComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: RoutingListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'details/:id',
            component: RoutingDetailsComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'new',
            component: RoutingCreationComponent,
            canActivate: [AuthGuard, OperatorGuard],
          },
          {
            path: 'edit/:id',
            canActivate: [AuthGuard, OperatorGuard],
            component: RoutingCreationComponent,
          },
        ],
      },
      {
        path: 'gateways',
        component: RouterOutletComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: GatewayListComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'import',
            component: GatewayImportComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'alerts',
        component: AlertingComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: 'enabledNonBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: resolver,
})
export class AppRoutingModule {}
