import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { map, Observable } from 'rxjs';
import { DataStoreService } from '../data/data-store.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class EditRoutingGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private data: DataStoreService,
  ) {}

  canActivate(): Observable<boolean> {
    return this.data.routing$.pipe(
      map(
        (routing) =>
          routing !== undefined &&
          !routing?.is_locked &&
          !!this.auth.isMaintainerOnRoute(routing.id),
      ),
    );
  }
}
