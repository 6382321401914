import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { JsonInputResult, MessageField } from 'src/models/device-type.models';
import { mandatoryFields } from '../parser-creation-helper';
import { ParserCreationService } from '../parser-creation.service';

@Component({
  selector: 'app-json-parser-editor',
  templateUrl: './json-parser-editor.component.html',
  styleUrls: ['./json-parser-editor.component.scss'],
})
export class JsonParserEditorComponent implements OnInit {
  mappingValidator = mandatoryFields;
  parserForm = this.fb.group({
    data_fields: [
      this.parserCreation.reconstructMapping(
        this.parserCreation.jsonBlueprint()?.config.json_sample,
        this.parserCreation.jsonBlueprint()?.config.data_fields,
      ),
    ],
  });

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public parserCreation: ParserCreationService,
  ) {}

  ngOnInit(): void {
    if (this.parserCreation.jsonBlueprint()) {
      this.updateMappingValidation(
        this.parserCreation.jsonBlueprint()!.config.data_fields!,
      );
    }
  }

  public fieldsParsed(parsingResult: JsonInputResult): void {
    this.updateMappingValidation(parsingResult.fields);
    this.parserForm.controls.data_fields.setValue(parsingResult.fields);
    this.parserCreation.setJsonBluePrintConfig({
      data_fields: parsingResult.fields,
      json_sample: parsingResult.json_sample,
    });
  }

  public updateMappingValidation(mappedFields: MessageField[]) {
    let mandatory = this.mappingValidator;
    const outputs = mappedFields
      .filter((field) => !field.duplicate_output && !field.deleted)
      .map((field) => field.output_name);

    mandatory = mandatory.map((field) => ({
      ...field,
      valid: outputs.includes(field.key_name),
    }));

    this.mappingValidator = mandatory;
  }

  public fieldChanged(field: MessageField, index: number): void {
    const dataFieldsControl = this.parserForm.controls.data_fields;
    dataFieldsControl.value[index] = field;
    this.updateMappingValidation(dataFieldsControl.value);
    this.parserCreation.setJsonBluePrintConfig({
      ...this.parserCreation.jsonBlueprint()?.config,
      variables: dataFieldsControl.value,
    });
  }
}
