import { DeviceTypeDto } from './device-type.models';

export interface Device {
  device_id: string;
  device_type_id: string;
  source_id: string;
  metadata: {
    [key: string]: string;
  };
  tags: string[];
  attribute_tags: string[];
  telemetrie: unknown;
}

export interface DeviceUpdateInfo {
  device_id: string;
  credentials?: IotcorePublicKeyCredential;
  name: string;
  description: string;
  lat: string;
  lng: string;

  attributeTags: string[];
}

export interface DevicePictureInfo {
  image_file: File;
}

export interface DeviceDto {
  device_id: string;
  device_type: DeviceTypeDto;
  device_type_id?: string;
  source_id: string;
  device_region?: string;
  calibration_functions: CalibrationFunction[];
  metadata: {
    lat: number;
    lng: number;
    name: string;
    [key: string]: string | number | boolean;
    userAccessFlags: boolean;
  };
  tags: DeviceTag[];
  site: SiteTag;
  attribute_tags: AttributeTag[];
  statistics: {
    low_battery: boolean | null;
    last_uplink: number | null;
    last_uplink_error: boolean | null;
    battery_powered: boolean | null;
    last_downlink?: number;
  };
  alarm: DeviceAlarm;
}

export interface DeviceFilter {
  project_id: string;
  brand_id: string;
  device_type_id: string;
  source: string;
  status: string;
}

export interface DeviceAlarm {
  battery: {
    enable: boolean;
  };
  connection: {
    enable: boolean;
    delay: number;
  };
}

export enum DeviceSourceType {
  IOT_CORE = 'iot_core',
  LORA_FIREFLY = 'lora_firefly',
  PUB_SUB = 'pub_sub',
  SIGFOX = 'sigfox',
}

export interface DeviceTag {
  tag_id: string;
  hierarchy_prefix: string;
  post_decoding_function_name: string;
  pre_encoding_function_name: string;
}

export interface SiteTag {
  tag_id: string;
  metadata: {
    country: string;
    time_zone?: string;
    infra_Site: string;
    organization: string;
    iso_code: string;
    site_long_name: string;
    userAccessFlags: boolean;
    firefly_organization?: string;
    location: {
      _latitude: number;
      _longitude: number;
    };
  };
  alert: SiteNotification;
}

export interface SiteNotification {
  enable: boolean;
  unix_cron: string;
}

export interface SiteNotificationConfig {
  enable: boolean;
  cron: string;
  timezone: string;
}

export interface AttributeTag {
  tag_id: string;
  metadata: { [key: string]: string };
}

export interface DeviceMonitoringStats {
  devices: number;
  devices_with_last_uplink_error: number;
  devices_with_low_battery: number;
  devices_with_low_battery_last_uplink_error: number;
  devices_with_no_warning: number;
  devices_with_no_message_yet: number;
}

export interface BrandInfo {
  name: string;
  description: string;
  image_file: File;
}

export interface FireflyDeviceInfo {
  name: string;
  EUI: string;
  region: DeviceRegion;
  deviceTypeId: string;
  applicationKey: string;
  hierarchyPrefix: string;
  projectIds: string[];
  attributeTags: string[];
  metadata: { [key: string]: unknown };
}

export type DeviceRegion = 'EU868' | 'US915' | 'AU915' | 'IN865' | 'AS923';

export interface DefaultDeviceInfo {
  name: string;
  deviceId: string;
  deviceTypeId: string;
  hierarchyPrefix: string;
  projectIds: string[];
  attributeTags: string[];
  metadata: { [key: string]: unknown };
}

export interface IotCoreDeviceInfo {
  name: string;
  id: string; // /^[a-z][A-Za-z0-9+.%-_~]*[A-Za-z0-9]$/g
  logLevel: IotcoreLogLevel;
  deviceTypeId: string;
  hierarchyPrefix: string;

  blocked: boolean;
  credentials: IotcorePublicKeyCredential;
  projectIds: string[];
  attributeTags: string[];
  metadata: { [key: string]: unknown };
}

export interface IotcorePublicKeyCredential {
  format: PublicKeyFormat;
  key: string;
}

export enum PublicKeyFormat {
  RSA_PEM = 'RSA_PEM',
  RSA_X509_PEM = 'RSA_X509_PEM',
  ES256_PEM = 'ES256_PEM',
  ES256_X509_PEM = 'ES256_X509_PEM',
}

export enum IotcoreLogLevel {
  NONE = 'NONE',
  ERROR = 'ERROR',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
}

type ExtraCSVInfo = 'description' | 'lat' | 'lng';

export const fireflyDeviceCSVInfo: {
  [key in keyof FireflyDeviceInfo | ExtraCSVInfo]?: string;
} = {
  name: 'Device name',
  EUI: 'Device EUI (16 chars & hexadecimal)',
  applicationKey: 'Application key (32 chars & hexadecimal)',
  region: "'EU868' or 'US915' or 'AU915' or 'IN865' or 'AS923'",
  description: 'Description',
  lat: 'Latitude',
  lng: 'Longitude',
};

export const iotCoreDeviceCSVInfo: {
  [key in keyof IotCoreDeviceInfo | ExtraCSVInfo]?: string;
} = {
  name: 'Device name',
  id: 'Device ID (Alphanumeric & - . _ + % ~ )',
  description: 'Description',
  lat: 'Latitude',
  lng: 'Longitude',
};

export interface CalibrationFunction {
  function_id: string;
  args: number[];
  field: string;
}
